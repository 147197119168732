<div class="header-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-10 col-md-12">
                <div class="header-widget">
                    <ul>
                        <li><i class="icofont-email"></i> obaayangburenspellingbee@gmail.com</li>
                        <li><i class="icofont-location-pin"></i> Afin Oba Ayamgburen, Palace Road Ikorodu Lagos</li>
                        <li><i class="icofont-phone"></i> <a href="tel:+2347030333363">+234 703 0333 363</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-2">
                <div class="header-social text-end">
                    <ul>
                        <li><a target="_blank" href="https://facebook.com/obaayangburenandthebee"><i class="icofont-facebook"></i></a></li>
                        <li><a target="_blank" href="https://instagram.com/obaayangburenandthebee"><i class="icofont-instagram"></i></a></li>
                    </ul>
                </div>
            </div>              
        </div>
    </div>
</div>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/thebee.png" alt="Oba Ayangburen and The Bee Logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand logo" href="index.html">
                    <img src="assets/img/thebee.png" alt="Oba Ayangburen and The Bee Logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto">
                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Home <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 1</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 2</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 3</a></li>
                            </ul>
                        </li> -->
                        
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Services <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/service" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service one</a></li>

                                <li class="nav-item"><a routerLink="/service-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services two</a></li>

                                <li class="nav-item"><a routerLink="/service-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service three</a></li>

                                <li class="nav-item"><a routerLink="/service-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service details</a></li>
                            </ul>
                        </li> -->
                        
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">The Bee <i class="icofont-rounded-right"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                                <li class="nav-item"><a routerLink="/rules" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Rules</a></li>
                                <li class="nav-item"><a routerLink="/sponsors" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sponsors</a></li>
                                <li class="nav-item"><a routerLink="/partners" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Partners</a></li>
                                <li class="nav-item"><a routerLink="/supports" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Supports</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>

                        <li class="nav-item"><a routerLink="/winners" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Winners</a></li>


                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Registration <i class="icofont-rounded-right"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/participation" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">More About Participation</a></li>
                                <li class="nav-item"><a routerLink="/school-registration" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">School </a></li>
                                <li class="nav-item"><a routerLink="/speller-registration" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Speller</a></li>
                                <li class="nav-item"><a routerLink="/coach-registration" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coach </a></li>
                                <li class="nav-item"><a routerLink="/coordinator" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coordinator </a></li>
                                <li class="nav-item"><a routerLink="/volunteer-form" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Volunteer </a></li>
                                <li class="nav-item"><a routerLink="/sponsor-child" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sponsor A Child </a></li>
                                <li class="nav-item"><a routerLink="/corporate-sponsor" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Corporate Sponsor </a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/volunteers" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Volunteers</a></li>


                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>

                        <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right sidebar</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog details</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/recent-project" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Recent Project</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Page</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Team <i class="icofont-rounded-right"></i></a>
                            
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/team-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team two</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>-->
                    </ul> 

                    <!-- <div class="navbar-button">
                        <a routerLink="/download"> Spelling Words</a>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>
</div>