import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-corporate-sponsor',
  templateUrl: './corporate-sponsor.component.html',
  styleUrls: ['./corporate-sponsor.component.scss']
})

export class CorporateSponsorComponent {

  constructor(public afs: AngularFirestore) { }

  loadshow: boolean
  loading: string
  message: string

  full_name: string
  phoneno: number
  emailaddress: string
  desc: string

  async  submit() {
    const id = this.afs.createId()
    this.loadshow = false
    this.loading = 'sending...'
  await  setTimeout(() => {
        this.afs.collection('corporate_sponsors').doc(id).set({
          organization_name: this.full_name,
           phoneno: this.phoneno, emailaddress: this.emailaddress, desc: this.desc
      }).then(() => {      
        setTimeout(() => {
          this.loadshow = true
          this.message = 'Form submitted successful.'
        }, 3000)

      }).finally(() => {
        this.full_name = '',   this.phoneno = 0,  
        this.emailaddress = '', this.desc = ''
      })
    }, 10000)


  }

}
