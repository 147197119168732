import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-winners',
  templateUrl: './winners.component.html',
  styleUrls: ['./winners.component.scss']
})
export class WinnersComponent implements OnInit {
  data = [
    {previewImageSource: 'assets/img/winners/2022/DSC_9261.jpg', imageSource: 'assets/img/winners/2022/DSC_9261.jpg'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4405.JPG', imageSource: 'assets/img/winners/2022/BBB_4405.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4409.JPG', imageSource: 'assets/img/winners/2022/BBB_4409.JPG'},
    {previewImageSource: 'assets/img/winners/2022/DSC_9880.jpg', imageSource: 'assets/img/winners/2022/DSC_9880.jpg'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4410.JPG', imageSource: 'assets/img/winners/2022/BBB_4410.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4421.JPG', imageSource: 'assets/img/winners/2022/BBB_4421.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4147.JPG', imageSource: 'assets/img/winners/2022/BBB_4147.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4146.JPG', imageSource: 'assets/img/winners/2022/BBB_4146.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4425.JPG', imageSource: 'assets/img/winners/2022/BBB_4425.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4429.JPG', imageSource: 'assets/img/winners/2022/BBB_4429.JPG'},

    {previewImageSource: 'assets/img/winners/2022/BBB_4430.JPG', imageSource: 'assets/img/winners/2022/BBB_4430.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4432.JPG', imageSource: 'assets/img/winners/2022/BBB_4432.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4434.JPG', imageSource: 'assets/img/winners/2022/BBB_4434.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4435.JPG', imageSource: 'assets/img/winners/2022/BBB_4435.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4437.JPG', imageSource: 'assets/img/winners/2022/BBB_4437.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4440.JPG', imageSource: 'assets/img/winners/2022/BBB_4440.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4486.JPG', imageSource: 'assets/img/winners/2022/BBB_4486.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4488.JPG', imageSource: 'assets/img/winners/2022/BBB_4488.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4489.JPG', imageSource: 'assets/img/winners/2022/BBB_4489.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4490.JPG', imageSource: 'assets/img/winners/2022/BBB_4490.JPG'},

    {previewImageSource: 'assets/img/winners/2022/BBB_4491.JPG', imageSource: 'assets/img/winners/2022/BBB_4491.JPG'},
    {previewImageSource: 'assets/img/winners/2022/BBB_4492.JPG', imageSource: 'assets/img/winners/2022/BBB_4492.JPG'},
  ]

  data2 = [
    {previewImageSource: 'assets/img/winners/2023/_DSC8407.jpg', imageSource: 'assets/img/winners/2023/_DSC8407.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC8316.jpg', imageSource: 'assets/img/winners/2023/_DSC8316.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC8318.jpg', imageSource: 'assets/img/winners/2023/_DSC8318.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC8359.jpg', imageSource: 'assets/img/winners/2023/_DSC8359.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC7946.jpg', imageSource: 'assets/img/winners/2023/_DSC7946.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC8148.jpg', imageSource: 'assets/img/winners/2023/_DSC8148.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC8404.jpg', imageSource: 'assets/img/winners/2023/_DSC8404.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC8409.jpg', imageSource: 'assets/img/winners/2023/_DSC8409.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC8411.jpg', imageSource: 'assets/img/winners/2023/_DSC8411.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC8413.jpg', imageSource: 'assets/img/winners/2023/_DSC8413.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC8418.jpg', imageSource: 'assets/img/winners/2023/_DSC8418.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC8418.jpg', imageSource: 'assets/img/winners/2023/_DSC8418.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC7977.jpg', imageSource: 'assets/img/winners/2023/_DSC7977.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC8450.jpg', imageSource: 'assets/img/winners/2023/_DSC8450.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC8433.jpg', imageSource: 'assets/img/winners/2023/_DSC8433.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC8429.jpg', imageSource: 'assets/img/winners/2023/_DSC8429.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC8428.jpg', imageSource: 'assets/img/winners/2023/_DSC8428.jpg'},
    {previewImageSource: 'assets/img/winners/2023/_DSC8426.jpg', imageSource: 'assets/img/winners/2023/_DSC8426.jpg'},

  ]



  constructor() { }

  ngOnInit() {
  }
}
