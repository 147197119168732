<!-- First Section Start -->

<div class="home-style-three home-bg-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-md-7 offset-lg-1">
                        <div class="home-text-three">
                            <h1>Oba Ayangburen and <span>the Bee</span></h1>
                            <p>Oba Ayangburen and the Bee is a royal spelling bee competition set to promote 
                                literacy, foster communication and public speaking skills and serve as a tool 
                                to bridge the educational gap between different schools, communities and states.
                                It  involve the participation of thousands of primary school children between 
                                the ages of 7-10 aacross the nation that would participate in a spelling 
                                challenge till a winner emerges. It is also tailored to foster tourism 
                                and promote the Ikorodu Division in Lagos Nigeria as this will be the destination
                                for all our grand finales.</p>
                            <p>Join us as we begin the next edition of Oba Ayangburen and the Bee.</p>
                            
                            <div class="theme-button">
                                <a routerLink="/speller-registration" class="default-btn active-btn">Speller Registration</a>
                                <a routerLink="/coach-registration" class="default-btn">Coach Registration</a>
                            </div>
                     
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-5 p-0">
                        <div class="home-image">
                            <img src="assets/img/bee.png" alt="home image">
                        </div>
                    </div>
                </div>
                <!--Start Popup-->
                <!-- <div class="popup" id="popup">
                    <h2>Our BEE FOR 2nd Edition 
                        <button class="close" (click)="rp($event)">x</button>
                    </h2>
                    <img src="assets/img/winner2023.jpg" alt="">
                </div> -->
                <!--End Popup-->

                <div class="home-three-shapes">
                    <img src="assets/img/process-pattern-3.png" alt="shape">
                    <img src="assets/img/process-pattern-4.png" alt="shape">
                    <img src="assets/img/process-pattern-5.png" alt="shape">
                    <img src="assets/img/process-pattern-6.png" alt="shape">
                    <img src="assets/img/process-pattern-7.png" alt="shape">
                    <img src="assets/img/process-pattern-6.png" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- First Section End -->

<!-- Sponsor Section Start -->

<section class="recent-project-section pt-100 pb-70">
    <div class="container-fluid p-0">
        <div class="section-head text-center">
            <!-- <span>Over the years</span> -->
            <h2>Our Sponsors</h2>

        </div>



        <div class="recent-project-slider owl-carousel owl-theme">
            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/sponsors/KINGSCOLLEGE.png" alt="Oba Ayangburen & The Bee, Sponsor Logo KINGSCOLLEGE" >
                    <!-- <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="#" class="popup-gallery">
                                    <i class="icofont-eye-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="recent-project-item">
                <div class="recent-project-item">
                    <div class="project-img">
                        <img src="assets/img/sponsors/epeetechsolution.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Beloxxi" >
                    </div>
                </div>
            </div>

            <div class="recent-project-item">
                <div class="recent-project-item">
                    <div class="project-img">
                        <img src="assets/img/sponsors/techtalk.png" alt="Tech Talk With Afo (Tech Quest)" >
                    </div>
                </div>
            </div>

            <div class="recent-project-item">
                <div class="recent-project-item">
                    <div class="project-img">
                        <img src="assets/img/sponsors/beloxxi.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Beloxxi" >
                    </div>
                </div>
            </div>


            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/sponsors/ikd_townhall.jpeg" alt="Oba Ayangburen & The Bee, Sponsor Logo Ikorodu Town Hall">
                </div>
            </div>

            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/sponsors/ikorodu_oga.jpeg" alt="Oba Ayangburen & The Bee, Sponsor Logo Ikorodu Oga">
                </div>
            </div>

            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/sponsors/ginger.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Ginger">
                </div>
            </div>

            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/sponsors/afed.jpeg" alt="Oba Ayangburen & The Bee, Sponsor Logo AFED">
                </div>
            </div>

            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/sponsors/ayangburen_market.jpeg" alt="Oba Ayangburen & The Bee, Sponsor Logo Ayangburen Market">
                </div>
            </div>

        </div>
    </div>
</section>

<!-- Sponsor Section End -->

<!-- Success Section Start -->

<section class="about-style-two pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="section-head">
                    <h3 class="mob">Previous Edition Was A Success</h3>
                </div>
                <div class="about-image">
                    <img src="assets/img/winner2023.jpg" alt=" 2nd Edition Winner's Photo">

                    <div class="counter-section">
                        <div class="counter-area">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-4 ">
                                    <div class="counter-text">
                                        <h2><span class="counter">2,500</span></h2>
                                        <p>Spellers</p>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-4 ">
                                    <div class="counter-text">
                                        <h2><span class="counter">1,050</span></h2>
                                        <p>Coaches</p>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-4 ">
                                    <div class="counter-text">
                                        <h2><span class="counter">983</span></h2>
                                        <p>Schools</p>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-4 ">
                                    <div class="counter-text">
                                        <h2><span class="counter">35</span></h2>
                                        <p>Corporate Supports</p>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-4 ">
                                    <div class="counter-text">
                                        <h2><span class="counter">2</span></h2>
                                        <p>Sponsors</p>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-4 ">
                                    <div class="counter-text">
                                        <h2><span class="counter">34</span></h2>
                                        <p>SME Supports</p>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-4 ">
                                    <div class="counter-text">
                                        <h2><span class="counter">8</span></h2>
                                        <p>Partners</p>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-4 ">
                                    <div class="counter-text">
                                        <h2><span class="counter">4</span></h2>
                                        <p>Awards</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pl-35">
                <div class="section-head">
                    <span class="lap">Previous Edition Was A Success</span>
                    <h2>The Giant of Africa</h2>
                    <p>Oba Ayangburen and the bee is a royal spelling bee competition created for encouraging sense of 
                        leadership in our children at young age, using not too formal educational method. It is tailored 
                        to be the biggest spelling bee competition in Africa.
                    </p>

                </div>
                <div class="about-text">  
                    <div class="theme-button">
                        <a routerLink="/corporate-sponsor" class="default-btn active-btn">corporate Sponsor</a>
                        <a routerLink="/sponsor-form" class="default-btn">Sponsor a Child</a>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Success Section End -->

<!-- <div class="service-style-three pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
            <span>Services</span>
            <h2>Viscous Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>
  
        <div class="service-slider owl-carousel owl-theme">
            <div class="service-item">
                <i class="flaticon-cleaning-spray"></i>
                <h3>Professional Cleaning</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>

                <div class="theme-button">
                    <a routerLink="/service-details" class="default-btn">Read More</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-clean-house"></i>
                <h3>House Cleaning</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>

                <div class="theme-button">
                    <a routerLink="/service-details" class="default-btn">Read More</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-wiper"></i>
                <h3>Office Cleaning</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>

                <div class="theme-button">
                    <a routerLink="/service-details" class="default-btn">Read More</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-cleaning-spray"></i>
                <h3>Professional Cleaning</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>

                <div class="theme-button">
                    <a routerLink="/service-details" class="default-btn">Read More</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>                  
        </div>
    </div>
</div> -->


<!-- OAATB Mission Section Start -->

<div class="process-section process-style-three pb-70">
    <div class="container">
        <div class="section-head text-center">
            <span>The giant of Africa</span>
            <h2>Welcome to <span> OAATB</span></h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-touch"></i>
                    <h3>Vision Statement</h3>
                    <p>Raising leaders whose actions will inspire their contemporaries to dream more, learn more, do more and become more.</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div> 
            
            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-world"></i>
                    <h3>Aims & Objectives</h3>
                    <p>Preparing the African children for leadership responsibility and social engagement via unconventional educational means</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div> 

            <div class="col-md-4 col-sm-6 offset-sm-3 offset-md-0">
                <div class="process-card">
                    <i class="icofont-recycle-alt"></i>
                    <h3>About
                        Oba Ayangbure & The Bee</h3>
                        <div class="theme-button">
                            <a routerLink="/about" class="default-btn active-btn">More Details</a>
                        </div>                
                </div>
            </div> 
        </div>

        <div class="process-shapes">
            <img src="assets/img/process-pattern-3.png" alt="shape">
            <img src="assets/img/process-pattern-4.png" alt="shape">
            <img src="assets/img/process-pattern-5.png" alt="shape">
            <img src="assets/img/process-pattern-6.png" alt="shape">
            <img src="assets/img/process-pattern-7.png" alt="shape">
            <img src="assets/img/process-pattern-6.png" alt="shape">
        </div>
    </div>
</div>

<!-- OAATB Mission Section End -->

<!-- The King Speak Section Start -->

<section class="why-us">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 p-0">
                <div class="why-us-img"></div>
            </div>

            <div class="col-lg-6">
                <div class="why-us-text ptb-100">
                    <div class="section-head">
                        <!-- <span>Why Choose Us</span> -->
                        <h2 class=" king" >HRM OBA (ENGR.) KABIR ADEWALE SHOTOBI The Ayangburen Of Ikorodu Kingdom </h2>
                        <p class="text-center" style="text-transform: uppercase; color: red;">Punch Lines</p>
                    </div>

                    <div class="media d-flex">
                        <i class="icofont-check align-self-start mr-3"></i>
                        <div class="media-body">
                           <h5 class="mb-20">The Development Of Every Community Is Dependant On The Development Of Its
                                Children. And This Is One Of The Reasons I'm Building Leadership Through This 
                                Spelling Bee Competition.
                            </h5>

                        </div>
                    </div>

                    <div class="media d-flex">
                        <i class="icofont-check align-self-start mr-3"></i>
                        <div class="media-body">
                            <h5 class="mb-20">I Am Specially Inviting Every Well Meaning Individuals, Government,
                                Non-Governmental And Corporate Organizations All Over The World To Join Me In 
                                This Lives Changing Journey.
                           </h5>
                        </div>
                    </div>

                    <div class="media d-flex">
                        <i class="icofont-check align-self-start mr-3"></i>
                        <div class="media-body">
                            <h5 class="mb-20">Immediately I saw The Content Of This Spelling Bee Competition, 
                                I Got Hooked To It With A Convinction That It Is Going To Be The Biggest In Africa.
                            </h5>
                        </div>
                    </div>

                    <div class="media d-flex">
                        <i class="icofont-check align-self-start mr-3"></i>
                        <div class="media-body">
                            <h5 class="mb-20">I'm UsinG this Medium To Invite Every Child Across The African 
                                Continent To My Domain, The Ikorodu Oga Kingdom To Participate In The Royal Competition.
                            </h5>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- The King Speak Section End -->


<!-- 
<section class="recent-project-section pt-100 pb-70">
    <div class="container-fluid p-0">
        <div class="section-head text-center">
            <span>Our Works</span>
            <h2>Our Recent Works</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="recent-project-slider owl-carousel owl-theme">
            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/recent-work/6.png" alt="image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/6.png" class="popup-gallery">
                                    <i class="icofont-eye-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="project-text">
                    <h3><a routerLink="/">Hotel Cleaning</a></h3>
                    <p>Apartment Cleaning</p>
                </div>
            </div>

            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/recent-work/7.png" alt="image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/7.png" class="popup-gallery">
                                    <i class="icofont-eye-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="project-text">
                    <h3><a routerLink="/">Glass Cleaning</a></h3>
                    <p>Apartment Cleaning</p>
                </div>
            </div>

            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/recent-work/9.png" alt="image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/9.png" class="popup-gallery">
                                    <i class="icofont-eye-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="project-text">
                    <h3><a routerLink="/">House Cleaning</a></h3>
                    <p>Apartment Cleaning</p>
                </div>
            </div>

            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/recent-work/10.png" alt="image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/10.png" class="popup-gallery">
                                    <i class="icofont-eye-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="project-text">
                    <h3><a routerLink="/">Housing Estate</a></h3>
                    <p>Apartment Cleaning</p>
                </div>
            </div>

            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/recent-work/8.png" alt="image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/8.png" class="popup-gallery">
                                    <i class="icofont-eye-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="project-text">
                    <h3><a routerLink="/">Resort Cleaning</a></h3>
                    <p>Apartment Cleaning</p>
                </div>
            </div>

            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/recent-work/9.png" alt="image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/9.png" class="popup-gallery">
                                    <i class="icofont-eye-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="project-text">
                    <h3><a routerLink="/">House Cleaning</a></h3>
                    <p>Apartment Cleaning</p>
                </div>
            </div>
        </div>
    </div>
</section> -->


<!-- top 5 finalist Section Start -->

<section class="team-section pb-20 pt-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Our <span>Top 5 Finalist</span></h2>
            <p>The top 5 finalist was hosted by HRM Oba ENGR Kabir Adewale shotobi and his wife Olori 
                Kudirat Shotobi, and they got over N500,000 cash support!!!
            </p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/finalist/First.jpg" alt="1ST POSITION: CHAERI MARVELLOUS. SURULERE LGA.">

                        <!-- <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div> -->
                    </div>

                    <div class="team-text">
                        <h4>CHAERI MARVELLOUS</h4>
                        <p>SURULERE LGA.</p>
                    </div>
                </div>
            </div> 
            
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/finalist/Second.jpg" alt="2ND POSITION: ABDULLAHI  ABDULAZEEM. IKORODU LGA.">
                    </div>

                    <div class="team-text">
                        <h4>ABDULLAHI  ABDULAZEEM</h4>
                        <p>IKORODU LGA.</p>
                    </div>
                </div>
            </div> 

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/finalist/Third.jpg" alt="3RD POSITION: SANNI ABDULLAH. IKORODU LGA.">
                    </div>

                    <div class="team-text">
                        <h4>SANNI ABDULLAH</h4>
                        <p>IKORODU LGA.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/finalist/Fourth.jpg" alt="4TH POSITION: AKINSIKU IBRAHIM.  AMUWO-ODOFIN LGA.">
                    </div>

                    <div class="team-text">
                        <h4>AKINSIKU IBRAHIM</h4>
                        <p>AMUWO-ODOFIN LGA.</p>
                    </div>
                </div>
            </div> 

                  <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/finalist/Fifth.jpg" alt="5TH POSITION: OLALEYE TOLUWANIMI. IKEJA LGA.">
                    </div>

                    <div class="team-text">
                        <h4>OLALEYE TOLUWANIMI</h4>
                        <p>IKEJA LGA.</p>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</section>

<!-- top 5 finalist Section End -->

<!-- Prize Section Start -->

<section class="pricing-section pb-70 pt-100">
    <div class="container">
        <div class="section-head text-center">
            <h2><span>PRIZE/REWARDS</span></h2>
            <p>Outstanding finalists at the competition will be rewarded as follows:</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>1st position</h4>
                        <h2>(OVERALL WINNER)</h2>
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li><i class="icofont-check-circled"></i> N9,000,000 worth of scholarship </li>
                            <li><i class="icofont-check-circled"></i> Educational android tablet </li>
                            <li><i class="icofont-check-circled"></i> Ikorodu kids' ambassador badge (staff of office to be presented by the paramount ruler of Ikorodu 
                                division, HRM (Engr.) Kabir Adewale Shotobi, the Ayangburen of Ikorodu kingdom), placing him/her in a vantage position to represent 
                                the voice of the Lagos state children, and advocate for the educational growth and right of every Nigerian child.
                            </li>
                            <li><i class="icofont-check-circled"></i> Corporate endorsement deal</li>
                            <li><i class="icofont-check-circled"></i> Shopping voucher</li>
                            <li><i class="icofont-check-circled"></i> 5-star educational certificate for the winner's school</li>
                            <li><i class="icofont-check-circled"></i> N50,000 cash price for the winner's spelling coach (Register Coach)</li>
                            <li><i class="icofont-check-circled"></i> Trophy and medal</li>
                            <li><i class="icofont-check-circled"></i> A free family weekend at the beach </li>
                            <li><i class="icofont-check-circled"></i> And more</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="price-card-area">
                    <div class="price-head-text">
                        <h4>1ST RUNNER UP</h4>
                        <!-- <h2>$<span>60</span>/month</h2> -->
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li><i class="icofont-check-circled"></i> N100,000 cash prize</li>
                            <li><i class="icofont-check-circled"></i> A medal</li>
                            <li><i class="icofont-check-circled"></i> N50,000 cash price for runner-up's spelling coach (Register Coach)</li>
                            <li><i class="icofont-check-circled"></i> Shopping voucher</li>
                            <li><i class="icofont-check-circled"></i> A free family weekend at the beach </li>
                            <li><i class="icofont-check-circled"></i> And more </li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 offset-md-3 col-sm-6  offset-lg-0 offset-sm-3">
                <div class="price-card-area">
                    <div class="price-head-text">
                        <h4>2ND RUNNER UP</h4>
                        <!-- <h2>$<span>159</span>/month</h2> -->
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li><i class="icofont-check-circled"></i> N100,000 cash prize</li>
                            <li><i class="icofont-check-circled"></i> A medal</li>
                            <li><i class="icofont-check-circled"></i> N20,000 cash price for runner-up's spelling coach (Register Coach)</li>
                            <li><i class="icofont-check-circled"></i> Shopping voucher</li>
                            <li><i class="icofont-check-circled"></i> A free family weekend at the beach </li>
                            <li><i class="icofont-check-circled"></i> And more </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Prize Section End -->



<!-- <div class="testimonial-section testimonial-bg ptb-100">
    <div class="testimonial-area">
        <div class="container">
            <div class="testimonial-slider owl-carousel owl-theme text-center">
                <div class="sin-testiText">
                    <h2>Stepheny Mc.man</h2>
                    <span>Co-worker of 3S</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do is ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices . Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>

                <div class="sin-testiText">
                    <h2>Jackson</h2>
                    <span>Graphics Designer of 3S</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do is ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices . Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>

                <div class="sin-testiText">
                    <h2>Amelia</h2>
                    <span>Web Developer of 3S</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do is ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices . Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <section class="blog-section pt-100">
    <div class="container">
        <div class="section-head text-center">
            <h2>Latest <span>Blogs</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="blog-slider owl-carousel owl-theme">
            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/1.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/2.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/3.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Self cleaning is the best drill</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/1.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/2.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/3.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Self cleaning is the best drill</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/1.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/2.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/3.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Self cleaning is the best drill</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>
        </div>                 
    </div>
</section> -->
