import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  constructor(public afs: AngularFirestore) { }

  getAllVolunteers() {
    return this.afs.collection('volunteer_registrations')
  }
  getAllCoordinators() {
   return this.afs.collection('coordinator_registrations')
  }

  getAllSpellers(){
    return this.afs.collection('speller_registrations')
  }
  getSpeller(id: any) {
    return this.afs.collection('speller_registrations').doc(id)
  }
}
