import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  fullname: string
  email: string
  message: string

  loadshow: boolean
  success_message: string

  constructor( public afs: AngularFirestore, ) { }

  ngOnInit() {
    this.loadshow = false
    this.success_message = ''
  }

  submit(fullname: string, email: string, message: string) {
    const id = this.afs.createId()
    this.afs.collection('audiences').doc(id).set({
      fullname: fullname, email: email, message: message
    }).then(() => {      
      this.loadshow = true
      this.success_message = 'Form submitted successful.'
    }).finally(() => {
      setTimeout(() => {
        this.loadshow = false
        this.fullname = '', this.message = '',  this.email = ''
      }, 3000)
    })
  }

}
