import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-coordinator',
  templateUrl: './coordinator.component.html',
  styleUrls: ['./coordinator.component.scss']
})

export class CoordinatorComponent {

  arrayGender = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    ];
  
  arrayStatus = [
    {value: 'Single', label: 'Single'},
    {value: 'Married', label: 'Married'},
    {value: 'Others', label: 'Others'},
  ]

  arrayDept = [
    {value: 'Zonal Coordinator', label: 'Zonal Coordinator'},
    {value: 'Coordinator', label: 'Coordinator'},
    {value: 'Assistant Coordinator', label: 'Assistant Coordinator'}

  ]

  arryDivisions = [
    {value: 'Ikorodu', label: 'Ikorodu'},
    {value: 'Badagry', label: 'Badagry'},
    {value: 'Ikeja', label: 'Ikeja'},
    {value: 'Lagos', label: 'Lagos'},
    {value: 'Epe', label: 'Epe'}
  ]
  arrayLocalG = [
    {value: 'Alimosho', label: 'Alimosho'},
    {value: 'Ajeromi-Ifelodun', label: 'Ajeromi-Ifelodun'},
    {value: 'Kosofe', label: 'Kosofe'},
    {value: 'Mushin', label: 'Mushin'},
    {value: 'Oshodi-Isolo', label: 'Oshodi-Isolo'},
    {value: 'Ojo', label: 'Ojo'},
    {value: 'Ikorodu', label: 'Ikorodu'},
    {value: 'Surulere', label: 'Surulere'},
    {value: 'Agege', label: 'Agege'},
    {value: 'Ifako-Ijaye', label: 'Ifako-Ijaye'},
    {value: 'Somolu', label: 'Somolu'},
    {value: 'Amuwo-Odofin', label: 'Amuwo-Odofin'},
    {value: 'Ikeja', label: 'Ikeja'},
    {value: 'Lagos Mainland', label: 'Lagos Mainland'},
    {value: 'Lagos Island', label: 'Lagos Island'},
    {value: 'Ibeju-Lekki', label: 'Ibeju-Lekki'},
    {value: 'Eti-Osa', label: 'Eti-Osa'},
    {value: 'Badagry', label: 'Badagry'},
    {value: 'Apapa', label: 'Apapa'},
    {value: 'Epe', label: 'Epe'},
  ]
  
  public sexies: any
  public lgrs: any
  public statues: any
  public depts: any
  public divisions:any

  public id: any
  public firstname: string
  public lastname: string
  public middlename: string = ''
  public sex: string = ''
  public lgr: string = ''
  public status: string = ''
  public dept: string = ''
  public division: string = '';
  public dateofbirth: any
  public occupation: string
  public employer_address: string
  public stateofresidence: string
  public homeaddress: string
  public phoneno: number
  public nin: string
  public whatsappno: string = ''
  public emailaddress: string
  public instagram: string = ''
  public facebook: string = ''
  public twitter: string = ''


  public maxFileSize = 3145728;
  public self_image: string="";
  public image: any
  uploadPercent: any;
  uploadFlag: any;
  public url: string;

  public maxFile = 2145728;
  public passport_image: string = ''
  public passport: any;
  uploadPercent2: any;
  uploadFlag2: any;
  public url2: string;

  public uploaderror: string
  public uploaderror2: string
  public loading: string
  public loadshow: boolean
  public errorshow: boolean
  public errorshow2: boolean
  public message: any

  constructor(public afs: AngularFirestore, public afa: AngularFireAuth, public router: Router,
    public afstorage: AngularFireStorage
    ) { }

  ngOnInit(): void {

  }

  changeSex(value) {
    this.sexies = value;
  }
  changeStatus(value) {
    this.statues = value;
  }
  changeDept(value) {
    this.depts = value;
  }
  changeLgr(value) {
    this.lgrs = value;
  }
  changeDiv(value) {
    this.divisions = value
  }

async submit(event) {
    this.loadshow = false
    this.loading = 'sending...'
    this.id = this.afs.createId()
  await  this.uploadDocument(this.id, this.image); 
    setTimeout(() => {
        this.afs.collection('coordinator_registrations').doc(this.id).set({
          firstname: this.firstname, lastname: this.lastname, middlename: this.middlename, sex: this.sexies, lgr: this.lgrs,
          stateofresidence: this.stateofresidence, homeaddress: this.homeaddress, phoneno: this.phoneno, whatsappno: this.whatsappno, 
          emailaddress: this.emailaddress, photo: this.image, passport_image: this.passport, marital_status: this.statues,
          occupation: this.occupation, employer_address: this.employer_address, dateofbirth:this.dateofbirth, nin: this.nin,
          coordinator_id: this.id, instagram: this.instagram, facebook: this.facebook, twitter: this.twitter, dept: this.depts, division: this.divisions
      }).then(() => {      
        setTimeout(() => {
          this.loadshow = true
          this.message = 'Form submitted successful.'
        }, 3000)
        this.router.navigate(['/referee-form/' + this.id])

      })
    }, 10000)


  }
  // Full Image
  async changeListener(event: any){
  this.uploadFlag = true;
  console.log(event)
    
// console.log(Date.now(), 'here now');
    const maxAllowedSize = this.maxFileSize;
    if (event.target.files[0].size > maxAllowedSize) {
      // Here you can ask your users to load correct file
      //  event.target.value = 'File size is to large'
      this.errorshow = false
       this.uploaderror = 'File size is to large'
      //  console.log('size error', event.target.value)
    }
    else {
      this.errorshow = true
      const file = event.target.files[0];
      const filePath ='coordinatorimage/' + Date.now() + '.jpg';
      const fileRef = this.afstorage.storage.ref(filePath);
      const task = fileRef.put(file);
    
      this.uploadPercent = task.snapshot.state;
  
      // console.log('here now 2', this.uploadPercent);
  
     await task.then((snap)=>{
        snap.ref.getDownloadURL().then((url)=> {
          this.uploadPercent =  task.snapshot.state;
          // console.log('here now 3', this.uploadPercent);
          // this.uploadDocument(this.id, url);
          // console.log("DOWNLOAD URL = ", url);
          this.image = url
          // console.log('here now 4', this.image);
  
        })
        
      })
    }

    
  }

  uploadDocument(information: string, id: any){
    const fileRef = this.afstorage.storage.ref().child('coordinatorimage/');
    const uploadTask = fileRef.putString(information);
    

    return new Promise((resolve, reject) => {
        uploadTask.on('state_changed', snapshot => {
    },  error => {
          reject(error);
    
    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        // console.log('teste resolve url'+ downloadURL);
    
        resolve ( {downloadURL});
      });
    
    });
    
    }); 
  }

  //Passport photo
  async changelistener(event: any){
    this.uploadFlag2 = true;
    console.log(event)
      
  // console.log(Date.now(), 'here now');
      const maxAllowedSize = this.maxFile;
      if (event.target.files[0].size > maxAllowedSize) {
        // Here you can ask your users to load correct file
        //  event.target.value = 'File size is to large'
        this.errorshow2 = false
         this.uploaderror2 = 'File size is to large'
        //  console.log('size error', event.target.value)
      }
      else {
        this.errorshow2 = true
        const file = event.target.files[0];
        const filePath ='coordinatorimage/' + Date.now() + '.jpg';
        const fileRef = this.afstorage.storage.ref(filePath);
        const task = fileRef.put(file);
      
        this.uploadPercent2 = task.snapshot.state;
    
        // console.log('here now 2', this.uploadPercent);
    
       await task.then((snap)=>{
          snap.ref.getDownloadURL().then((url)=> {
            this.uploadPercent2 =  task.snapshot.state;
            // console.log('here now 3', this.uploadPercent);
            // this.uploadDocument(this.id, url);
            // console.log("DOWNLOAD URL = ", url);
            this.passport = url
            // console.log('here now 4', this.image);
    
          })
          
        })
      }
  
      
    }
  
    uploadDocument2(information: string, id: any){
      const fileRef = this.afstorage.storage.ref().child('coordinatorimage/');
      const uploadTask = fileRef.putString(information);
      
  
      return new Promise((resolve, reject) => {
          uploadTask.on('state_changed', snapshot => {
      },  error => {
            reject(error);
      
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          // console.log('teste resolve url'+ downloadURL);
      
          resolve ( {downloadURL});
        });
      
      });
      
      }); 
    }
}
