import { Component } from '@angular/core';

@Component({
  selector: 'app-corporate-supports',
  templateUrl: './corporate-supports.component.html',
  styleUrls: ['./corporate-supports.component.scss']
})
export class CorporateSupportsComponent {

}
