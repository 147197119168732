<div class="team-title team-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="team-title-text">
                    <h2>Team Members Two</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Team Members Two
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="team-section team-style-four pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/1.png" alt="team member">

                        <div class="team-social">                           
                            <a href="#"><i class="icofont-facebook"></i></a>
                            <a href="#"><i class="icofont-twitter"></i></a>                  
                            <a href="#"><i class="icofont-instagram"></i></a>                        
                        </div>
                    </div>
                    <div class="team-text">
                        <h4>John Smith</h4>
                        <p>Head of Cleaner</p>
                    </div>
                </div>
            </div> 
            
            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/2.png" alt="team member">

                        <div class="team-social">                           
                            <a href="#"><i class="icofont-facebook"></i></a>
                            <a href="#"><i class="icofont-twitter"></i></a>                  
                            <a href="#"><i class="icofont-instagram"></i></a>                        
                        </div>
                    </div>
                    <div class="team-text">
                        <h4>Marry</h4>
                        <p>Cleaner</p>
                    </div>
                </div>
            </div> 

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/3.png" alt="team member">

                        <div class="team-social">                           
                            <a href="#"><i class="icofont-facebook"></i></a>
                            <a href="#"><i class="icofont-twitter"></i></a>                  
                            <a href="#"><i class="icofont-instagram"></i></a>                        
                        </div>
                    </div>
                    <div class="team-text">
                        <h4>John Smith</h4>
                        <p>Office Cleaner</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/4.png" alt="team member">

                        <div class="team-social">                           
                            <a href="#"><i class="icofont-facebook"></i></a>
                            <a href="#"><i class="icofont-twitter"></i></a>                  
                            <a href="#"><i class="icofont-instagram"></i></a>                        
                        </div>
                    </div>
                    <div class="team-text">
                        <h4>Eve</h4>
                        <p>Assistent</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/5.png" alt="team member">

                        <div class="team-social">                           
                            <a href="#"><i class="icofont-facebook"></i></a>
                            <a href="#"><i class="icofont-twitter"></i></a>                  
                            <a href="#"><i class="icofont-instagram"></i></a>                        
                        </div>
                    </div>
                    <div class="team-text">
                        <h4>Zeck Smith</h4>
                        <p>Car Cleaner</p>
                    </div>
                </div>
            </div> 
            
            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/2.png" alt="team member">

                        <div class="team-social">                           
                            <a href="#"><i class="icofont-facebook"></i></a>
                            <a href="#"><i class="icofont-twitter"></i></a>                  
                            <a href="#"><i class="icofont-instagram"></i></a>                        
                        </div>
                    </div>
                    <div class="team-text">
                        <h4>Thinka Smith</h4>
                        <p>Office Cleaner</p>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</div>