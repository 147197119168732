import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolService } from 'src/app/services/school.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent {

  public id: any;
  downloadcode: string;
  public speller: any;

  public msg: boolean = false

  constructor(public afs: AngularFirestore, public afa: AngularFireAuth, public router: Router,
     public service: SchoolService, public ar: ActivatedRoute
   ) { }

  async  ngOnInit() {
    this.id = this.ar.snapshot.paramMap.get('spellerreg_id')

    await this.service.getSpeller(this.id).valueChanges().subscribe( ref => {
     this.speller = ref;
     // console.log(this.speller)
   })
    // for (let elem of this.allSpellers) {
    //   console.log(elem.active);
    // }
  }

  download(downloadcode, ) {
    if(downloadcode !== this.speller.spellerCode || this.speller.active == false ) {
      this.msg = true
        console.log('wrong', downloadcode )
      }
      else if(downloadcode == this.speller.spellerCode && this.speller.active == true) {
        this.msg = false
        window.open('assets/img/cvtemplate.pdf')
      }
    // this.allSpellers.forEach(elem => {
    //   console.log(elem);
    // });
     

  }

}
