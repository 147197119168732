import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-sponsor-child',
  templateUrl: './sponsor-child.component.html',
  styleUrls: ['./sponsor-child.component.scss']
})
export class SponsorChildComponent {

  constructor(public afs: AngularFirestore) { }

  loadshow: boolean
  loading: string
  message: string

  organization_name: string
  number_children: number
  phoneno: number
  emailaddress: string
  desc: string

  async  submit() {
    const id = this.afs.createId()
    this.loadshow = false
    this.loading = 'sending...'
  await  setTimeout(() => {
        this.afs.collection('child_sponsors').doc(id).set({
          organization_name: this.organization_name, number_children: this.number_children,
           phoneno: this.phoneno, emailaddress: this.emailaddress, desc: this.desc
      }).then(() => {      
        setTimeout(() => {
          this.loadshow = true
          this.message = 'Form submitted successful.'
        }, 3000)

      }).finally(() => {
        this.organization_name = '', this.number_children = 0,  this.phoneno = 0,  
        this.emailaddress = '', this.desc = ''
      })
    }, 10000)


  }

}
