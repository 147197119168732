import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-school-registration',
  templateUrl: './school-registration.component.html',
  styleUrls: ['./school-registration.component.scss']
})
export class SchoolRegistrationComponent implements OnInit {



  public hm_whatsappno: string = ''
  public hm_phoneno: string = ''
  public schoolemailaddress: string
  public schoolwhatsappno: string = ''
  public schoolphoneno: number
  public schooladdress: string
  public hm_name: string
  public schoolname: string

  public time: any
  public loading: string
  public loadshow: boolean
  public message: any

  constructor(public afs: AngularFirestore, public afa: AngularFireAuth, public router: Router,) { }

  ngOnInit(): void {
    // this.time = moment.utc(moment.duration(5000, 'seconds').asMilliseconds()).format('h:mm a')

  }

  submit() {
    this.loadshow = false
    this.loading = 'sending...'
    //moment.utc(moment.duration(500, 'seconds').asMilliseconds()).format('h:mm')
    // moment.duration(200, "seconds").asMilliseconds(). format("h:mm");
    const id = this.afs.createId()  
      this.afs.collection('school_registrations').doc(id).set({
        hm_whatsappno: this.hm_whatsappno, hm_phoneno: this.hm_phoneno, schoolemailaddress: this.schoolemailaddress,
        schoolwhatsappno: this.schoolwhatsappno, schoolphoneno: this.schoolphoneno, schooladdress: this.schooladdress, 
        hm_name: this.hm_name, schoolname: this.schoolname, schoolreg_id: id
      }).then(() => {
        
        setTimeout(() => {
          this.loadshow = true
          this.message = 'Form submitted successful.'
        }, 4000)

      }).finally(() => {
        this.hm_whatsappno = '', this.hm_phoneno = '', this.schoolemailaddress = '', this.schoolwhatsappno = '', this.schoolphoneno = 0,
        this.schooladdress = '', this.hm_name = '', this.schoolname = ''
      })

  }
}
