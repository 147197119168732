<div class="service-title service-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Participation</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-rounded-double-right"></i>Participation </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="main-service-area single-service-page pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="contact-section">
                        <div class="contact-area">
                            <div class="row align-items-center">            
                                <div class="col-lg-12">
                                    <div class="contact-text">
                                        <div class="section-head">
                                            <h2>More About Participation</h2>
                                            <ul class="subtext">
                                                <li class="listitem">There is a slot for spelling bee coach. A spelling bee coach is a private tutor who practice with the contestants (speller) their study words, the coach also go home with cash prize and award if his or her speller won. <br>
                                                    Schools are to choose maximum of 3 spelling bee coach and register them here. In addition to their duties of coaching the spellers, the spelling bee coaches also serves as middlemen between the school and the organizers.
                                                </li>
                                                <li class="listitem">If your school has not received any official invitation, kindly click on school button under the registration to fill in your school details</li>
                                                <li class="listitem">As parents or guardians, you can also register your child to participate individually. Kindly click here to register your child</li>

                                            </ul>
                                        </div>
                  
                                    </div>               
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>