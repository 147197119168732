<div class="service-title service-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Rules</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-rounded-double-right"></i>Rules </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="main-service-area single-service-page pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="contact-section">
                        <div class="contact-area">
                            <div class="row align-items-center">            
                                <div class="col-lg-12">
                                    <div class="contact-text">
                                        <div class="section-head">
                                            <h2>Rules of Engagement</h2>
                                            <p class="subtext">A speller is given two minutes and thirty seconds from when a word is first pronounced 
                                                to spell a word in its entirety. The first two minutes are known as “Regular Time”, the final 
                                                thirty seconds is known as “Finish Time”. During this time limit, a speller is allowed to ask the 
                                                pronouncer for the following information:</p>
                                            <ul class="subtext">
                                                <li class="listitem">Definition of the word.</li>
                                                <li class="listitem">The word's part of speech.</li>
                                                <li class="listitem">The word's usage in a sentence.</li>
                                                <li class="listitem">The word's language(s) of origin (not the complete etymology, even though some spellers refer to the language(s) of origin as the etymology).</li>
                                                <li class="listitem">Alternate pronunciations of the word.</li>
                                                <li class="listitem">Alternate definitions of the word.</li>
                                                <li class="listitem">Whether or not the word contains a specified root; this may only be asked providing the speller can state the root in question, the root's language of origin, and the root's definition.</li>
                                            </ul>
                                            <p class="subtext">Once Regular Time has expired, a chime will sound, and the judges will inform the speller that Finish Time has begun. The speller gets the benefit of 
                                                watching a clock count down from thirty seconds, as no timing devices are allowed onstage. No more requests may be made to the pronouncer,
                                                 and the speller must begin spelling the word. Any speller that exceeds the time limit is automatically eliminated on the grounds that 
                                                 judges will not acknowledge any letters given by the speller after the end of Finish Time</p>
                                            <p class="subtext">A speller is allowed once during the bee to ask for Bonus Time which is a one minute continuation of Regular Time. Bonus Time must be requested 
                                                before Finish Time commences.</p>
                                            <p class="subtext">A speller is also allowed to start over spelling a word; however, he or she may not change the letters they have already said. Doing so counts 
                                                as a misspell and automatic elimination.</p>
                                            <p class="subtext">Any speller that exhausts Regular Time twice will be subjected to Abbreviated Regular Time (90 seconds) instead of Regular Time.</p>
                                        </div>
                  
                                    </div>               
                                </div>
                            </div>
                        </div>
                </div>
            </div>
  
            
            <div class="col-lg-4 main-service-area blog-side-bar sidebar">
                <div class="service-sidebar">
                    <!-- <form class="search-form">
                        <div class="form-group">              
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit" class="btn btn-primary">
                                <i class="icofont-search-1"></i>
                            </button>
                        </div>
                    </form> -->
                    
                    <div class="service-list">
                        <!-- <p>Categories</p>                             -->
                        <ul>
                            <li>
                                <a routerLink="/school-registration">
                                    School Registration
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/">
                                    Speller Registration
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/coach-registration">
                                    Coach Registration
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/volunteer-form">
                                    Volunteer Registration
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/participation">
                                    More About Participation
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>

                        </ul>
                    </div>
                    <!-- <div class="discount-text">
                        <h1>Get <span>15% Discount</span> on your first Service</h1>

                        <div class="theme-button">
                            <a routerLink="/" class="default-btn">Book Now</a>
                        </div>

                        <div class="discount-shapes">
                            <img src="assets/img/counter/1.png" alt="shape">
                            <img src="assets/img/counter/2.png" alt="shape">
                            <img src="assets/img/counter/3.png" alt="shape">
                            <img src="assets/img/counter/4.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                            <img src="assets/img/counter/6.png" alt="shape">
                            <img src="assets/img/counter/7.png" alt="shape">
                            <img src="assets/img/counter/8.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>