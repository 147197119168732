import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularGallerySliderModule } from  'angular-gallery-slider';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';


import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore'


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServiceComponent } from './components/pages/service/service.component';
import { ServiceTwoComponent } from './components/pages/service-two/service-two.component';
import { ServiceThreeComponent } from './components/pages/service-three/service-three.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { RecentProjectComponent } from './components/pages/recent-project/recent-project.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TeamTwoComponent } from './components/pages/team-two/team-two.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { SchoolRegistrationComponent } from './components/pages/school-registration/school-registration.component';
import { CoachRegistrationComponent } from './components/pages/coach-registration/coach-registration.component';
import { RulesComponent } from './components/pages/rules/rules.component';
import { VolunteerFormComponent } from './components/pages/volunteer-form/volunteer-form.component';
import { GuarantorFormComponent } from './components/pages/guarantor-form/guarantor-form.component';
import { SpellerRegistrationComponent } from './components/pages/speller-registration/speller-registration.component';
import { ParticipationComponent } from './components/pages/participation/participation.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { SponsorsComponent } from './components/pages/sponsors/sponsors.component';
import { PartnersComponent } from './components/pages/partners/partners.component';
import { SupportsComponent } from './components/pages/supports/supports.component';
import { VolunteersComponent } from './components/pages/volunteers/volunteers.component';
import { GuardianFormComponent } from './components/pages/guardian-form/guardian-form.component';
import { CorporateSponsorComponent } from './components/pages/corporate-sponsor/corporate-sponsor.component';
import { SponsorChildComponent } from './components/pages/sponsor-child/sponsor-child.component';
import { CoordinatorComponent } from './components/pages/coordinator/coordinator.component';
import { RefereeFormComponent } from './components/pages/referee-form/referee-form.component';
import { CongratsComponent } from './components/pages/congrats/congrats.component';
import { WinnersComponent } from './components/pages/winners/winners.component';
import { SuccessfullComponent } from './components/pages/successfull/successfull.component';
import { DownloadComponent } from './components/pages/download/download.component';
import { SpellersComponent } from './components/pages/prevous/spellers/spellers.component';
import { CoachesComponent } from './components/pages/prevous/coaches/coaches.component';
import { SchoolsComponent } from './components/pages/prevous/schools/schools.component';
import { CorporateSupportsComponent } from './components/pages/prevous/corporate-supports/corporate-supports.component';
import { SmeSupportsComponent } from './components/pages/prevous/sme-supports/sme-supports.component';
import { AwardsComponent } from './components/pages/prevous/awards/awards.component';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    HeaderComponent,
    FooterComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    AboutComponent,
    ServiceComponent,
    ServiceTwoComponent,
    ServiceThreeComponent,
    ServiceDetailsComponent,
    BlogComponent,
    BlogRightSidebarComponent,
    BlogDetailsComponent,
    PricingComponent,
    RecentProjectComponent,
    ErrorComponent,
    TeamComponent,
    TeamTwoComponent,
    ContactComponent,
    SchoolRegistrationComponent,
    CoachRegistrationComponent,
    RulesComponent,
    VolunteerFormComponent,
    GuarantorFormComponent,
    SpellerRegistrationComponent,
    ParticipationComponent,
    GalleryComponent,
    SponsorsComponent,
    PartnersComponent,
    SupportsComponent,
    VolunteersComponent,
    GuardianFormComponent,
    CorporateSponsorComponent,
    SponsorChildComponent,
    CoordinatorComponent,
    RefereeFormComponent,
    CongratsComponent,
    WinnersComponent,
    SuccessfullComponent,
    DownloadComponent,
    SpellersComponent,
    CoachesComponent,
    SchoolsComponent,
    CorporateSupportsComponent,
    SmeSupportsComponent,
    AwardsComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, 
    AngularGallerySliderModule,
    FormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
