<div class="team-title team-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="team-title-text">
                    <h2>Winners</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Winners
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="team-section team-style-four pb-70">
    <div class="container">
        <div class="contact-text">
            <div class="section-head">
                <h2>Chaeri Marvellous (winner OAATB 2023)</h2>
            </div>
        </div>

        <gl-gallery [value]="data2" [itemSize]="50">
            <ng-template template="preview" let-data>
                <img src="{{ data.previewImageSource }}" alt="preview"/>
            </ng-template>
            <ng-template template="item" let-data>
                <img src="{{ data.imageSource }}" alt="image" style="block-size: 50px; inline-size: 100px;"/>
            </ng-template>
        </gl-gallery>

    </div>

    <div class="container">
        <div class="contact-text">
            <div class="section-head">
                <h2>Zainab Abdullah (winner OAATB 2022)</h2>
            </div>
        </div>

        <gl-gallery [value]="data" [itemSize]="50">
            <ng-template template="preview" let-data>
                <img src="{{ data.previewImageSource }}" alt="preview"/>
            </ng-template>
            <ng-template template="item" let-data>
                <img src="{{ data.imageSource }}" alt="image" style="block-size: 50px; inline-size: 100px;"/>
            </ng-template>
        </gl-gallery>

    </div>
</div>