<div class="about-title about-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="about-title-text">
                    <h1>Sponsors</h1>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-rounded-double-right"></i> Sponsors</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-style-two about-style-three pt-100 pb-70">
    <div class="section-head text-center">
        <h1 class="connection">Our Sponsors</h1>
    </div>
    <div class="container-fluid">
        <div class="row align-items-center">

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/lsha.jpg" alt="Oba Ayangburen & The Bee, Sponsor Logo Lagos State Government" width="50%">
                </div>
                <div class="section-head">
                    <h2>Lagos State Government</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/KINGSCOLLEGE.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Kings Field College" width="50%">
                </div>
                <div class="section-head">
                    <h2>Kings Field <br> College</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/techtalk.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Tech Talk With Afo (Tech Quest)" width="50%">
                </div>
                <div class="section-head">
                    <h2>Tech Quest</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/epeetechsolution.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Epee Tech Solution" width="50%">
                </div>
                <div class="section-head">
                    <h2>Epee Tech <br> Solution</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/afed.jpeg" alt="Oba Ayangburen & The Bee, Sponsor Logo AFED" width="50%">
                </div>
                <div class="section-head">
                    <h2>AFED</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/3crown.jpg" alt="Oba Ayangburen & The Bee, Sponsor Logo 3crown" width="50%">
                </div>
                <div class="section-head">
                    <h2>3crown</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/ayangburen_market.jpeg" alt="Oba Ayangburen & The Bee, Sponsor Logo Ayangburen Market" width="50%">
                </div>
                <div class="section-head">
                    <h2>Ayangburen <br> Market</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/akada_Tv.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Akada TV" width="50%">
                </div>
                <div class="section-head">
                    <h2>Akada Tv</h2>
                </div>
            </div>
            

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/big.jpg" alt="Oba Ayangburen & The Bee, Sponsor Logo BIG" width="50%">
                </div>
                <div class="section-head">
                    <h2>BIG</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/bokku.jpg" alt="Oba Ayangburen & The Bee, Sponsor Logo Bokku Mart" width="50%">
                </div>
                <div class="section-head">
                    <h2>Bokku Mart</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/beloxxi.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Beloxxi" width="50%">
                </div>
                <div class="section-head">
                    <h2>Beloxxi</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/bukka.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Bukka Hut"  width="50%">
                </div>
                <div class="section-head">
                    <h2>Bukka Hut</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/coldstone.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Cold Stone" width="50%">
                </div>
                <div class="section-head">
                    <h2> Cold Stone</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/colegate.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Colgate" width="50%">
                </div>
                <div class="section-head">
                    <h2>Colgate</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/comfident.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Confident" width="50%">
                </div>
                <div class="section-head">
                    <h2>Confident</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/ikd_townhall.jpeg" alt="Oba Ayangburen & The Bee, Sponsor Logo Ikorodu Town Hall" width="50%">
                </div>
                <div class="section-head">
                    <h2>Ikorodu <br> Town Hall</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/chalian.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Chalian" width="50%">
                </div>
                <div class="section-head">
                    <h2>Chalian</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/Busiqfabrics.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Busiq Fabrics" width="50%">
                </div>
                <div class="section-head">
                    <h2>Busiq <br> Fabrics</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/DAIC.png" alt="Oba Ayangburen & The Bee, Sponsor Logo DAIC" width="50%">
                </div>
                <div class="section-head">
                    <h2>DAIC</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/justrite.jpg" alt="Oba Ayangburen & The Bee, Sponsor Logo justrite" width="50%">
                </div>
                <div class="section-head">
                    <h2>Just Rite</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/irish.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Irish Bakery" width="50%">
                </div>
                <div class="section-head">
                    <h2>Irish Bakery</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/diva_cakes.jpeg" alt="Oba Ayangburen & The Bee, Sponsor Logo diva_cakes" width="50%">
                </div>
                <div class="section-head">
                    <h2>Diva Cakes</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/slot.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Slot" width="50%">
                </div>
                <div class="section-head">
                    <h2>Slot</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/open.jpeg" alt="Oba Ayangburen & The Bee, Sponsor Logo OPEN" width="50%">
                </div>
                <div class="section-head">
                    <h2>OPEN</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/Impact_News.jpg" alt="Oba Ayangburen & The Bee, Sponsor Logo Impact News" width="50%">
                </div>
                <div class="section-head">
                    <h2>Impact News</h2>
                </div>
            </div>


            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/ikorodu_oga.jpeg" alt="Oba Ayangburen & The Bee, Sponsor Logo Ikorodu Oga" width="50%">
                </div>
                <div class="section-head">
                    <h2>Ikorodu Oga</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/Fanafillit.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Fanafillit" width="50%">
                </div>
                <div class="section-head">
                    <h2>Fanafillit</h2>
                </div>
            </div>
            
            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/ginger.png" alt="Oba Ayangburen & The Bee, Sponsor Logo ginger" width="40%">
                </div>
                <!-- <div class="section-head">
                    <h2>Ginger</h2>
                </div> -->
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/Glorious_Opportunity.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Glorious Opportunity" width="70%">
                </div>
                <!-- <div class="section-head">
                    <h2>Glorious <br> Opportunity</h2>
                </div> -->
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/oshodi.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Oshodi Arts Gallery" width="50%">
                </div>
                <div class="section-head">
                    <h2>Oshodi Arts <br> Gallery</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/heads.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Headway Events" width="50%">
                </div>
                <div class="section-head">
                    <h2>Headway <br> Events</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/yemfem.jpeg" alt="Oba Ayangburen & The Bee, Sponsor Logo Yemfem Fabrics" width="50%">
                </div>
                <div class="section-head">
                    <h2>Yemfem <br> Fabrics</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/INSPERA.png" alt="Oba Ayangburen & The Bee, Sponsor Logo INSPERA" width="50%">
                </div>
                <div class="section-head">
                    <h2>INSPERA</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/peacemass.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Peace Mass Transit" width="50%">
                </div>
                <div class="section-head">
                    <h2>Peace Mass</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/sangloos.jpg" alt="Oba Ayangburen & The Bee, Sponsor Logo sangloos" width="50%">
                </div>
                <div class="section-head">
                    <h2>Sangloos <br> Media</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/Prestiges2.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Prestiges" width="50%">
                </div>
                <div class="section-head">
                    <h2>Prestiges</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/Prestiges2.png" alt="Oba Ayangburen & The Bee, Sponsor Logo dbard Creative Media" width="50%">
                </div>
                <div class="section-head">
                    <h2>Dbard Crative <br> Media</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/T1.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Dooshima's Empire" width="50%">
                </div>
                <div class="section-head">
                    <h2>Dooshima's <br> Empire</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/supports/Wasolastore.png" alt="Oba Ayangburen & The Bee, Sponsor Logo Wasola Store" width="50%">
                </div>
                <div class="section-head">
                    <h2>Wasola <br> Store</h2>
                </div>
            </div>
        </div>
    </div>
</section>

