import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-speller-registration',
  templateUrl: './speller-registration.component.html',
  styleUrls: ['./speller-registration.component.scss']
})
export class SpellerRegistrationComponent implements OnInit {

  arrayGender = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    ];
    hobbies = [
      {id: 1, name: 'Singing', isChecked:false}, 
      {id: 2, name: 'Dancing', isChecked:false}, 
      {id: 3, name: 'Reading', isChecked:false}, 
      {id: 4, name: 'Cooking', isChecked:false}, 
      {id: 5, name: 'Magic', isChecked:false}, 
    ];
    interests = [
      {id: 1, name: 'Music', isChecked:false}, 
      {id: 2, name: 'Presentation', isChecked:false}, 
      {id: 3, name: 'Coding', isChecked:false}, 
      {id: 4, name: 'Model', isChecked:false}, 
    ];
  
  public sexies: any
  public checkedHobbies = []
  public checkedInterest = []

  public id: any;
  public firstname: string
  public lastname: string
  public middlename: string = ''
  public sex: string = ''
  public dateofbirth: any
  public nationality: string
  public stateoforigin: string
  public stateofresidence: string
  public homeaddress: string
  public sponsordetails: string
  public whatsappno: string = ''
  public emailaddress: string
  public speller_Rcode: string

  public maxFileSize = 3145728;
  public self_image: string="";
  public image: any
  uploadPercent: any;
  uploadFlag: any;
  public url: string;

  public uploaderror: string
  public loading: string
  public loadshow: boolean
  public errorshow: boolean
  public message: any

  public SpellerCode: any;

  constructor(public afs: AngularFirestore, public afa: AngularFireAuth, public router: Router,
    public afstorage: AngularFireStorage
    ) { }

  ngOnInit(): void {
  }

  changeSex(value) {
    this.sexies = value;
    // console.log('here i am again',this.sexies)
  }
  selectHobbies(value){
    this.checkedHobbies.push(value.name);
    // console.log('here now2', this.checkedHobbies)

    // for (let i = 0 ; i < value.name; i++) {
    //   if(value.name[i].checked){
    //     this.checkedHobbies.push(value[i].value);
    //     console.log('here now2', this.checkedHobbies)

    // }
    // //  arr.push(checkboxes[i])

    // }
    // this.hobbyPick = value.name
    // console.log('here now2', this.hobbyPick)
    // this.checkedHobbies.push(value)
    // console.log('here now3', this.checkedHobbies)

    // this.hobbies.push(value)
    // const ids =[];
    // const checkListFilter = this.hobbies;
    // ids = checkListFilter.filter(item=>isChecked).map(item=>item.id);
    
    }
  selectInterest(value){
      this.checkedInterest.push(value.name);
  }

async  submit(event) {
    this.loadshow = false
    this.loading = 'sending...'
    this.id = this.afs.createId()
    const regCode = Math.floor(9000 * Math.random());
    this.SpellerCode = 'OAATB'+ regCode
  await  this.uploadDocument(this.id, this.image); 
    setTimeout(() => {
        this.afs.collection('speller_registrations').doc(this.id).set({
          firstname: this.firstname, lastname: this.lastname, middlename: this.middlename, sex: this.sexies, dateofbirth: this.dateofbirth,
          nationality: this.nationality, stateoforigin: this.stateoforigin, stateofresidence: this.stateofresidence, sponsordetails: this.sponsordetails,
          homeaddress: this.homeaddress, speller_Rcode: this.speller_Rcode, photo: this.image, spellerreg_id: this.id, hobbies: this.checkedHobbies,
          interests: this.checkedInterest, spellerCode: this.SpellerCode, active: false
      }).then(() => {   
        this.firstname = '', this.lastname = '', this.middlename = '', this.sex = '', this.dateofbirth = '', this.nationality = '',
        this.stateoforigin = '', this.stateofresidence = '', this.homeaddress = '', this.sponsordetails = '',
         this.speller_Rcode = '', this.image = '', this.checkedHobbies = [], this.checkedInterest = []   
        setTimeout(() => {
          this.loadshow = true
          this.message = 'Form submitted successful.'
        }, 3000)
        this.router.navigate(['/guardian-form/' + this.id])
      })
    })


  }

async  changeListener(event: any){
  this.uploadFlag = true;
  // console.log(event)
    
// console.log(Date.now(), 'here now');
    const maxAllowedSize = this.maxFileSize;
    if (event.target.files[0].size > maxAllowedSize) {
      // Here you can ask your users to load correct file
      //  event.target.value = 'File size is to large'
      this.errorshow = false
       this.uploaderror = 'File size is to large'
      //  console.log('size error', event.target.value)
    }
    else {
      this.errorshow = true
      const file = event.target.files[0];
      const filePath ='spellerimage/' + Date.now() + '.jpg';
      const fileRef = this.afstorage.storage.ref(filePath);
      const task = fileRef.put(file);
    
      this.uploadPercent = task.snapshot.state;
  
      // console.log('here now 2', this.uploadPercent);
  
      
  
     await task.then((snap)=>{
        snap.ref.getDownloadURL().then((url)=> {
          this.uploadPercent =  task.snapshot.state;
          // console.log('here now 3', this.uploadPercent);
          // this.uploadDocument(this.id, url);
          // console.log("DOWNLOAD URL = ", url);
          this.image = url
          // console.log('here now 4', this.image);
  
        })
        
      })
    }

    
  }

uploadDocument(information: string, id: any){
    const fileRef = this.afstorage.storage.ref().child('spellerimage/');
    const uploadTask = fileRef.putString(information);
    

    return new Promise((resolve, reject) => {
        uploadTask.on('state_changed', snapshot => {
    },  error => {
          reject(error);
    
    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        // console.log('teste resolve url'+ downloadURL);
    
        resolve ( {downloadURL});
      });
    
    });
    
    }); 
  }
}
