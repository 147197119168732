import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-three',
  templateUrl: './home-three.component.html',
  styleUrls: ['./home-three.component.scss']
})
export class HomeThreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public rp(event){
    document.getElementById('popup').addEventListener('click', function(){
      document.getElementById('popup').style.visibility='hidden';
      });
    // document.getElementById('popup').classList.remove('open-popup');

 }

}
