<div class="contact-section" style="margin-bottom: 500px; padding-top: 80px;">
    <div class="container">
        <div class="contact-area">
            <div class="row align-items-center">

                <div class="col-lg-12 col-md-12">
                    <div class="contact-text">
                        <div class="section-head">
                            <div class="contact-form" style="margin-top: 50px; text-align: center;">
                                <form id="contactForm" >
                                    <div class="row">
                                        <div class="col-md-12 col-sm-6" *ngIf="msg">
                                            <p style="color: red">Your code is invalid or not confirmed yet. 
                                                <span style="color: black">Contact the support team</span></p>
                                        </div>
                                        <div class="col-md-12 col-sm-6">
                                            <div class="form-group mb-3">
                                                <input type="text" name="fullname" id="fullname" class="form-control" 
                                                placeholder="Enter Your Code"
                                                [(ngModel)]="downloadcode" [ngModelOptions]="{standalone:true}" required >
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <button type="submit" class="default-btn page-btn" 
                                            (click)="download(downloadcode )">Download Now </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>               
                </div>
            </div>
        </div>
    </div>
</div>