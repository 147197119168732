<div class="service-title service-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Coach Registration</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-rounded-double-right"></i>Coach Registration </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="main-service-area single-service-page pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="contact-section">
                        <div class="contact-area">
                            <div class="row align-items-center">            
                                <div class="col-lg-12">
                                    <div class="contact-text">
                                        <div class="section-head">
                                            <h2>OAATB23 Coach Registration</h2>
                                            <!-- <p class="subtext">Kindly provide details of your school below, your letter will be dispatched to your school 
                                                within 7 working days.</p> -->

                                            <!-- <p>{{time}}</p> -->
                                        </div>
                                        <div class="contact-form">
                                            <form id="contactForm">
                                                <div class="row">
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label>First Name <span class="request">*</span></label>
                                                            <input required type="text" name="firstname" id="firstname" class="form-control"
                                                            [(ngModel)]="firstname" [ngModelOptions]="{standalone:true}">
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label>Last Name <span class="request">*</span></label>
                                                            <input required type="text" name="lastname" id="lastname" class="form-control"
                                                            [(ngModel)]="lastname" [ngModelOptions]="{standalone:true}">
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label>Middle Name <span> (Optional)</span></label>
                                                            <input  type="text" name="middlename" id="middlename" class="form-control"
                                                            [(ngModel)]="middlename" [ngModelOptions]="{standalone:true}">
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label>Sex <span class="request">*</span></label>
                                                            <select  class="custom-select form-control" value="Select one"
                                                            [(ngModel)]='sex' (change)="changeSex(sex)" [ngModelOptions]="{standalone: true}">
                                                            <option value="" selected="selected" hidden="hidden">Select Option</option>
                                                            <option *ngFor="let sex of arrayGender" value="{{sex?.value}}" >{{sex?.label}}</option>
                                                            </select>
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label>Date of Birth <span class="request">*</span></label>
                                                            <input required type="date" name="dateofbirth" id="dateofbirth" class="form-control"
                                                            [(ngModel)]="dateofbirth" [ngModelOptions]="{standalone:true}">
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label>Nationality <span class="request">*</span></label>
                                                            <input required type="text" name="nationality" id="nationality" class="form-control"
                                                            [(ngModel)]="nationality" [ngModelOptions]="{standalone:true}">
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label>State of Origin <span class="request">*</span></label>
                                                            <input required type="text" name="stateoforigin" id="stateoforigin" class="form-control"
                                                            [(ngModel)]="stateoforigin" [ngModelOptions]="{standalone:true}">
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label>State of Residence <span class="request">*</span></label>
                                                            <input required type="text" name="stateofresidence" id="stateofresidence" class="form-control"
                                                            [(ngModel)]="stateofresidence" [ngModelOptions]="{standalone:true}">
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label>Home Address <span class="request">*</span></label>
                                                            <input required type="text" name="homeaddress" id="homeaddress" class="form-control"
                                                            [(ngModel)]="homeaddress" [ngModelOptions]="{standalone:true}">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label> Phone Number <span class="request">*</span></label>
                                                            <input required type="number" name="phoneno" id="phoneno" class="form-control"
                                                            [(ngModel)]="phoneno" [ngModelOptions]="{standalone:true}">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label> WhatsApp Number <span> (Optional)</span></label>
                                                            <input type="number" name="whatsappno" id="whatsappno" class="form-control" 
                                                            [(ngModel)]="whatsappno" [ngModelOptions]="{standalone:true}">
                                                        </div>
                                                    </div>
                                                
                                                    <div class="col-md-12 col-sm-6">
                                                        <div class="form-group mb-3">
                                                            <label> Email Address <span class="request">*</span></label>
                                                            <input required type="email" name="emailaddress" id="emailaddress" class="form-control"
                                                            [(ngModel)]="emailaddress" [ngModelOptions]="{standalone:true}">
                                                        </div>
                                                    </div>
                                                
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label>Speller Name <span class="request">*</span></label>
                                                            <input required type="text" name="spellername" id="spellername" class="form-control" 
                                                            [(ngModel)]="spellername" [ngModelOptions]="{standalone:true}">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label>Speller Unique Code <span class="request">*</span></label>
                                                            <input required  type="number" name="spellercode" id="spellercode" class="form-control"
                                                            [(ngModel)]="spellercode" [ngModelOptions]="{standalone:true}">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label>Add a Full Photograph (Coach) <span class="request">*</span></label>
                                                            <br>
                                                            <span>maximum size is 3MB</span>
                                                            <br>
                                                            <span class="error" *ngIf="!errorshow">{{uploaderror}}</span>
                                                            <input required  type="file" name="photo" id="photo" class="form-control"
                                                            [(ngModel)]="self_image" [ngModelOptions]="{standalone:true}"
                                                            [accept]="['.jpg','.jpeg','.png']" 
                                                            (change)="changeListener($event)">
                                                        </div>
                                                    </div>
                                                
                                                    <div class="col-lg-12 col-md-12">
                                                        <button type="submit" class="default-btn page-btn" 
                                                        [disabled]="!firstname || !lastname || !sex || !dateofbirth || !nationality || !stateoforigin ||
                                                        !stateofresidence || !homeaddress || !phoneno || !emailaddress || !spellername || !spellercode || !self_image"
                                                        (click)="submit($event)"
                                                        >Submit</button>
                                                            <p *ngIf="!loadshow">{{loading}}</p>
                                                    </div>

                                                    <div class="col-lg-12 col-md-12">
                                                        <p class="success">{{message}}</p>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>               
                                </div>
                            </div>
                        </div>
                </div>
            </div>
  
            
            <div class="col-lg-4 main-service-area blog-side-bar sidebar">
                <div class="service-sidebar">
                    <!-- <form class="search-form">
                        <div class="form-group">              
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit" class="btn btn-primary">
                                <i class="icofont-search-1"></i>
                            </button>
                        </div>
                    </form> -->
                    
                    <div class="service-list">
                        <p>Other Links</p>
                        <ul>
                            <li>
                                <a routerLink="/speller-registration">
                                    Speller Registration
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/school-registration">
                                    School Registration
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/volunteer-form">
                                    Volunteer Registration
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/rules">
                                    Find Out About The Rules
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/participation">
                                    More About Participation
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="discount-text">
                        <h1>Get <span>15% Discount</span> on your first Service</h1>

                        <div class="theme-button">
                            <a routerLink="/" class="default-btn">Book Now</a>
                        </div>

                        <div class="discount-shapes">
                            <img src="assets/img/counter/1.png" alt="shape">
                            <img src="assets/img/counter/2.png" alt="shape">
                            <img src="assets/img/counter/3.png" alt="shape">
                            <img src="assets/img/counter/4.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                            <img src="assets/img/counter/6.png" alt="shape">
                            <img src="assets/img/counter/7.png" alt="shape">
                            <img src="assets/img/counter/8.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>