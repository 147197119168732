import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SchoolService } from 'src/app/services/school.service';

@Component({
  selector: 'app-volunteers',
  templateUrl: './volunteers.component.html',
  styleUrls: ['./volunteers.component.scss']
})
export class VolunteersComponent {

  public vols: any
  public coords: any

  constructor(public afs: AngularFirestore, public schoolRec: SchoolService) { }

  ngOnInit(): void {
    this.getAllVol()
  }

  async getAllVol() {
    this.schoolRec.getAllVolunteers().valueChanges().subscribe(ref => {
      this.vols = ref
    })
  }

}
