<div class="contact-section" style="margin-bottom: 500px; padding-top: 80px;">
    <div class="container">
        <div class="contact-area">
            <div class="row align-items-center">

                <div class="col-lg-12 col-md-12">
                    <div class="contact-text">
                        <div class="section-head">
                            <h2><span style='color: red;'> Congratulations! {{speller?.firstname}}, </span>  your registration is successful. </h2>
                            <p>Please click below WhatsApp link to access your</p>
                            <ul>
                                <li>1. Congratulatory letter</li>
                                <li>2. Copy of the official study words</li>
                            </ul>
                            <P> </P>
                            <P> </P>

                            <div class="contact-form" style="margin-top: 50px; text-align: center;">
                                <form id="contactForm">
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn page-btn" 
                                        (click)="whatsaap()">WhatsApp Message </button>
                                        <!-- <button type="submit" class="default-btn page-btn" 
                                        (click)="downloadbtn()">Download Spelling Words </button> -->
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>               
                </div>
            </div>
        </div>
    </div>
</div>