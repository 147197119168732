import { Component } from '@angular/core';

@Component({
  selector: 'app-spellers',
  templateUrl: './spellers.component.html',
  styleUrls: ['./spellers.component.scss']
})
export class SpellersComponent {

}
