<div class="team-title team-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="team-title-text">
                    <h2>Volunteers</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Volunteers
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="team-section team-style-four pt-100 pb-70">
    <div class="container">
        <div class="contact-text">
            <div class="section-head">
                <h2 class="text-center">Volunteers</h2>
                <!-- <p class="subtext">Kindly provide details of your school below, your letter will be dispatched to your school 
                    within 7 working days.</p> -->

                <!-- <p>{{time}}</p> -->
            </div>
        <div class="col-lg-12 main-service-area blog-side-bar sidebar">
            <div class="service-sidebar">             
                <div class="service-list">
                    <ul class=" grouplist col-12 text-center">
                        <li class="list col-lg-1 col-2">All</li>
                        <li class="list col-lg-1 col-3">Ikorodu</li>
                        <li class="list col-lg-1 col-3">Alimosho</li>
                        <li class="list col-lg-1 col-3">Ojo</li>
                        <li class="list col-lg-2 col-5">Ajeromi Ifelodun</li>
                        <li class="list col-lg-2 col-5">Oshodi Isolo</li>
                        <li class="list col-lg-1 col-3">Kosofe</li>
                        <li class="list col-lg-1 col-3">Mushin</li>
                        <li class="list col-lg-1 col-3">Surulere</li>
                        <li class="list col-lg-1 col-3">Agege</li>
                        <li class="list col-lg-2 col-5">Ifako Ijaye</li>
                        <li class="list col-lg-1 col-3">Somolu</li>
                        <li class="list col-lg-2 col-5">Amuwo Odofin</li>
                        <li class="list col-lg-2 col-5">Lagos Island</li>
                        <li class="list col-lg-1 col-3">Ikeja</li>
                        <li class="list col-lg-1 col-3">Eti Osa</li>
                        <li class="list col-lg-1 col-3">Apapa</li>
                        <li class="list col-lg-2 col-5">Lagos Mainland</li>
                        <li class="list col-lg-2 col-5">Ibeju Lekki</li>
                        <li class="list col-lg-1 col-2">Epe</li>
                        <li class="list col-lg-1 col-3">Badagry</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row" >
            <div class="col-lg-4 col-md-6" *ngFor=" let vol of vols">
                <div class="team-card">
                    <div class="team-img">
                        <img src="{{vol?.passport_image}}" alt="team member">

                        <div class="team-social">                           
                            <a href="#"><i class="icofont-facebook"></i></a>
                            <a href="#"><i class="icofont-twitter"></i></a>                  
                            <a href="#"><i class="icofont-instagram"></i></a>                        
                        </div>
                    </div>
                    <div class="team-text">
                        <h4>{{vol?.lastname}} {{vol?.firstname}}</h4>
                        <p>LGA: {{vol?.lgr}}</p>
                    </div>
                </div>
            </div> 

        </div>
    </div>
</div>