<div class="contact-section">
    <div class="container">
        <div class="contact-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="contact-img">
                        <img src="assets/img/contact.png" alt="contact image">      
                    </div>           
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="contact-text">
                        <div class="section-head">
                            <h2>We Love to <span> Hear from You</span></h2>
                            <p>Feel free and share with us. We will get you</p>
                        </div>

                        <div class="contact-form">
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-md-12 col-sm-6">
                                        <div class="form-group mb-3">
                                            <input type="text" name="fullname" id="fullname" class="form-control" placeholder="Your Full Name"
                                            [(ngModel)]="fullname" [ngModelOptions]="{standalone:true}" required >
                                        </div>
                                    </div>
                                
                                    <div class="col-md-12 col-sm-6">
                                        <div class="form-group mb-3">
                                            <input type="email" name="email" id="email" class="form-control" placeholder="Your Email Address"
                                            [(ngModel)]="email" [ngModelOptions]="{standalone:true}">
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"
                                            [(ngModel)]="message" [ngModelOptions]="{standalone:true}"></textarea>
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn page-btn" 
                                        [disabled]="!fullname || !email || !message"
                                        (click)="submit(fullname, email, message)">Send Message</button>
                                    </div>
                                </div>
                                <div *ngIf="loadshow" class="success_msg">
                                    {{success_message}}
                                </div>
                            </form>
                        </div>
                    </div>               
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/foot_bee_light.png" alt="OBA AYANGBUREN & THE BEE - A royal spelling bee competition Logo">
                        </a>
                    </div>
                    <p>OBA AYANGBUREN & THE BEE - A royal spelling bee competition</p>

                    <!-- <div class="email">
                        <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL">
                            <button class="default-btn electronics-btn" type="submit"><i class="icofont-location-arrow"></i></button>
                        </form>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    <h3>Quick Links</h3>

                    <ul>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/sponsors">Sponsors</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/partners">Partners</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/supports">Supports</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/corporate-sponsor">corporate Sponsor</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/sponsor-child">Sponsor A Child</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/volunteers">Volunteers</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/participation">More About Participation</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    <h3>Quick Links</h3>

                    <ul>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/">Rules</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/about">About Us</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/">Speller Registration</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/">School Registration</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/contact">Contact Us</a></li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Location</h3>

                    <ul class="find-us">
                        <li>                                   
                            <i class="icofont-location-pin"></i> Afin Oba Ayamgburen,palace road
                            Ikorodu Lagos. c/o Scenerio Amusement Park,Igbodu Ikorodu Lagos     
                        </li>
                        <li>
                            <i class="icofont-phone"></i>
                            <a href="tel:+2347030333363">+234 703 0333 363</a>
                            <a href="tel:++2349121556160">+234 912 1556 160</a>

                        </li>
                        <li>
                            <i class="icofont-whatsapp"></i>
                            <a href="https://wa.me/+2347030333363">+234 703 0333 363</a>
                        </li>
                        <li>
                            <i class="icofont-ui-message"></i>
                            <a href="mailto:obaayangburenspellingbee@gmail.com">obaayangburenspellingbee@gmail.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="bottom-footer">
        <div class="container">
            <div class="row  align-items-center">
                <div class="col-lg-6">
                    <div class="footer-social">
                        <ul>
                            <li><a href="https://facebook.com/obaayangburenandthebee" target="_blank"><i class="icofont-facebook"></i></a></li>          
                            <li><a href="https://instagram.com/obaayangburenandthebee" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-text text-end">
                        <p>© Copyrights 2023 OAATB All rights reserved. Developed by <a href="https://epee.com.ng/" target="_blank"> EpeeTechSolution</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="top-btn">
    <i class="icofont-scroll-long-up"></i>
</div>