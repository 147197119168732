<div class="home-section">
    <div class="home-slider-area owl-carousel owl-theme">
        <div class="home-slider-item items-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>Your Hygienic Home Our Responsibility</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida.</p>
                            <div class="theme-button">
                                <a routerLink="/about" class="default-btn active-btn">Know More</a>
                                <a routerLink="/contact" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="home-slider-item items-bg2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>We provide High Quality Service</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida.</p>
                            <div class="theme-button">
                                <a routerLink="/about" class="default-btn active-btn">Know More</a>
                                <a routerLink="/contact" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="home-slider-item items-bg3">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>Our Working Process is Unique</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida.</p>
                            <div class="theme-button">
                                <a routerLink="/about" class="default-btn active-btn">Know More</a>
                                <a routerLink="/contact" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="service-section pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Viscous Provided <span>Services</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-award"></i>
                    <h3>Top Rated Service</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>
                    <div class="theme-button">
                        <a routerLink="/service-details" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card active-service">
                    <i class="flaticon-credit-card"></i>
                    <h3>Low Cost Service</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>
                    <div class="theme-button">
                        <a routerLink="/service-details" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-clock"></i>
                    <h3>Fastest Service</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>
                    <div class="theme-button">
                        <a routerLink="/service-details" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-cleaning-spray"></i>
                    <h3>Professional Cleaning</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>
                    <div class="theme-button">
                        <a routerLink="/service-details" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-clean-house"></i>
                    <h3>House Cleaning</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>
                    <div class="theme-button">
                        <a routerLink="/service-details" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-wiper"></i>
                    <h3>Office Cleaning</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>
                    <div class="theme-button">
                        <a routerLink="/service-details" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="service-shapes">
            <img src="assets/img/service/shapes/1.png" alt="shape">
            <img src="assets/img/service/shapes/2.png" alt="shape">
            <img src="assets/img/service/shapes/3.png" alt="shape">
            <img src="assets/img/service/shapes/4.png" alt="shape">
            <img src="assets/img/service/shapes/5.png" alt="shape">
            <img src="assets/img/service/shapes/6.png" alt="shape">
            <img src="assets/img/service/shapes/7.png" alt="shape">
            <img src="assets/img/service/shapes/8.png" alt="shape">
            <img src="assets/img/service/shapes/9.png" alt="shape">
            <img src="assets/img/service/shapes/10.png" alt="shape">
        </div>
    </div>
</section>

<section class="why-choose-section why-choose-bg">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 why-choose-img"></div>

            <div class="col-lg-5 offset-lg-6 offset-md-0">
                <div class="why-choose-text">
                    <div class="section-head">
                        <h2>Why Choose Us</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
                <div class="why-choose-accordian">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                    <a href="#" class="btn" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Expert Cleaners
                                    </a>
                                </h2>
                            </div>                           
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <h2 class="mb-0">
                                    <a href="#"  class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Latest Equipment
                                    </a>
                                </h2>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"     data-bs-parent="#accordionExample">
                                <div class="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <h2 class="mb-0">
                                    <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Clean & Fast Service
                                    </a>
                                </h2>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <h2 class="mb-0">
                                    <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Budget Oriented
                                    </a>
                                </h2>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div class="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="why-choose-contact">
                        <form>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Your Number">
                            </div>
                            <button type="submit" class="btn btn-primary">Request A Call</button>
                        </form>
                        <p>Get a free call for service</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="why-choose-shape">
            <img src="assets/img/why-choose/shape-1.png" alt="shape">
        </div>
    </div>
</section>

<div class="process-section pb-70">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-touch"></i>
                    <h3>Choose Services</h3>
                    <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry.</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div> 
            
            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-world"></i>
                    <h3>Booking Online</h3>
                    <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry.</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div> 

            <div class="col-md-4 col-sm-6 offset-sm-3 offset-md-0">
                <div class="process-card">
                    <i class="icofont-recycle-alt"></i>
                    <h3>Enjoy Cleaning</h3>
                    <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry.</p>
                </div>
            </div> 
        </div>
    </div>
</div>

<section class="pricing-section pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Our <span>Pricing Plan</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="price-card-area">
                    <div class="price-head-text">
                        <h4>Basic Plan</h4>
                        <h2>$<span>60</span>/month</h2>
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li><i class="icofont-check-circled"></i> Fully Satisfaction</li>
                            <li><i class="icofont-check-circled"></i> 5 Hours</li>
                            <li><i class="icofont-check-circled"></i> 1 time in a week</li>
                            <li><i class="icofont-check-circled"></i> Full Cleaning</li>
                            <li><i class="icofont-check-circled"></i> All Our Equipment</li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Booking Now</a>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>Standard Plan</h4>
                        <h2>$<span>99</span>/month</h2>
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li><i class="icofont-check-circled"></i> Fully Satisfaction</li>
                            <li><i class="icofont-check-circled"></i> 7 Hours</li>
                            <li><i class="icofont-check-circled"></i> 2 time in a week</li>
                            <li><i class="icofont-check-circled"></i> Full Cleaning</li>
                            <li><i class="icofont-check-circled"></i> All Our Equipment</li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Booking Now</a>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 offset-md-3 col-sm-6  offset-lg-0 offset-sm-3">
                <div class="price-card-area">
                    <div class="price-head-text">
                        <h4>Premium Plan</h4>
                        <h2>$<span>159</span>/month</h2>
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li><i class="icofont-check-circled"></i> Fully Satisfaction</li>
                            <li><i class="icofont-check-circled"></i> 10 Hours</li>
                            <li><i class="icofont-check-circled"></i> 3 time in a week</li>
                            <li><i class="icofont-check-circled"></i> Full Cleaning</li>
                            <li><i class="icofont-check-circled"></i> All Our Equipment</li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Booking Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="counter-section pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="offer-text">
                    <h2>Get <span>15% Discount</span> on your first Service</h2>

                    <div class="theme-button">
                        <a routerLink="/pricing" class="default-btn">Book Now</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="counter-area">
                    <div class="row">
                        <div class="col-lg-5 col-md-3 col-6 offset-lg-1">
                            <div class="counter-text">
                                <h2><span class="counter">1</span>K</h2>
                                <p>Job Done</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6">
                            <div class="counter-text">
                                <h2><span class="counter">989</span></h2>
                                <p>Happy Clients</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6 offset-lg-1">
                            <div class="counter-text">
                                <h2><span class="counter">45</span></h2>
                                <p>Experts</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6">
                            <div class="counter-text">
                                <h2><span class="counter">12</span></h2>
                                <p>Years of Experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="counter-shape">
            <img src="assets/img/counter/1.png" alt="shape">
            <img src="assets/img/counter/2.png" alt="shape">
            <img src="assets/img/counter/3.png" alt="shape">
            <img src="assets/img/counter/4.png" alt="shape">
            <img src="assets/img/counter/5.png" alt="shape">
            <img src="assets/img/counter/6.png" alt="shape">
            <img src="assets/img/counter/7.png" alt="shape">
            <img src="assets/img/counter/1.png" alt="shape">
            <img src="assets/img/counter/8.png" alt="shape">
            <img src="assets/img/counter/4.png" alt="shape">
            <img src="assets/img/counter/shape-1.png" alt="bubble shape">
            <img src="assets/img/counter/shape-1.png" alt="bubble shape">
        </div>
    </div>
</div>

<section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Our <span>Team Members</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/1.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>John Smith</h4>
                        <p>Head of Cleaner</p>
                    </div>
                </div>
            </div> 
            
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/2.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>Marry</h4>
                        <p>Cleaner</p>
                    </div>
                </div>
            </div> 

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/3.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>John Smith</h4>
                        <p>Office Cleaner</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/4.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>Eve</h4>
                        <p>Assistant</p>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</section>

<div class="testimonial-section testimonial-bg ptb-100">
    <div class="testimonial-area">
        <div class="container">
            <div class="testimonial-slider owl-carousel owl-theme text-center">
                <div class="sin-testiText">
                    <h2>Stepheny Mc.man</h2>
                    <span>Co-worker of 3S</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do is ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices . Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>

                <div class="sin-testiText">
                    <h2>Jackson</h2>
                    <span>Graphics Designer of 3S</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do is ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices . Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>

                <div class="sin-testiText">
                    <h2>Amelia</h2>
                    <span>Web Developer of 3S</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do is ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices . Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-section pt-100">
    <div class="container">
        <div class="section-head text-center">
            <h2>Latest <span>Blogs</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="blog-slider owl-carousel owl-theme">
            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/1.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/2.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/3.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Self cleaning is the best drill</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/1.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/2.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/3.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Self cleaning is the best drill</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/1.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/2.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/3.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Self cleaning is the best drill</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>
        </div>                 
    </div>
</section>