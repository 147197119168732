import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-guardian-form',
  templateUrl: './guardian-form.component.html',
  styleUrls: ['./guardian-form.component.scss']
})
export class GuardianFormComponent {

  public id: any
  public fullname: string;
  public homeaddress: string;
  public phoneno: number;
  public whatsappno: number;
  public email: string

  public loading: string
  public loadshow: boolean
  public errorshow: boolean
  public message: any

  constructor(public afs: AngularFirestore, public afa: AngularFireAuth, public router: Router,
    public afstorage: AngularFireStorage, public ar: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('spellerreg_id')
  }

  async  submit() {
    this.loadshow = false
    this.loading = 'sending...'
  await  setTimeout(() => {
        this.afs.collection('speller_registrations').doc(this.id).update({
          guard_fullname: this.fullname, guard_homeaddress: this.homeaddress, guard_phoneno: this.phoneno, 
          guard_whatsappno: this.whatsappno, guard_emailaddress: this.email, 
      }).then(() => {      
        setTimeout(() => {
          this.loadshow = true
          this.message = 'Form submitted successful.'
        }, 3000)

      }).finally(() => {
        this.fullname = '', this.whatsappno = 0, this.homeaddress = '', this.phoneno = 0,  this.email = ''
      })
    }, 10000)
    this.router.navigate(['/successfull/' + this.id])


  }

}
