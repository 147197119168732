import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolService } from 'src/app/services/school.service';

@Component({
  selector: 'app-successfull',
  templateUrl: './successfull.component.html',
  styleUrls: ['./successfull.component.scss']
})
export class SuccessfullComponent {

  public id: any
  public speller: any

  constructor(public afs: AngularFirestore, public afa: AngularFireAuth, public router: Router,
     public ar: ActivatedRoute, public service: SchoolService
    ) { }

   async ngOnInit() {
     this.id = this.ar.snapshot.paramMap.get('spellerreg_id')

     await this.service.getSpeller(this.id).valueChanges().subscribe( ref => {
      this.speller = ref;
      // console.log(this.speller)
    })

  }

  downloadbtn() {
    this.router.navigate(['/download/' +this.id])
  }

  whatsaap() {
    window.location.href="https://wa.me/+2347030333363?text=My%20name%20is%20....%20I%20just%20registered%20my%20child/ward%20for%20the%20Royal%20spelling%20bee%20competition,%20Im%20from%20....%20LGA"

  }

}
