import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-coach-registration',
  templateUrl: './coach-registration.component.html',
  styleUrls: ['./coach-registration.component.scss']
})
export class CoachRegistrationComponent implements OnInit {

  arrayGender = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    ];
  
  public sexies: any

  public firstname: string
  public lastname: string
  public middlename: string = ''
  public sex: string = ''
  public dateofbirth: any
  public nationality: string
  public stateoforigin: string
  public stateofresidence: string
  public homeaddress: string
  public phoneno: number
  public whatsappno: string = ''
  public emailaddress: string
  public spellername: string
  public spellercode: number

  public maxFileSize = 3145728;
  public self_image: string="";
  public image: any
  uploadPercent: any;
  uploadFlag: any;
  public url: string;

  public uploaderror: string
  public loading: string
  public loadshow: boolean
  public errorshow: boolean
  public message: any

  constructor(public afs: AngularFirestore, public afa: AngularFireAuth, public router: Router,
    public afstorage: AngularFireStorage
    ) { }

  ngOnInit(): void {

  }

  changeSex(value) {
    this.sexies = value;
    // console.log('here i am again',this.sexies)
  }

async  submit(event) {
    this.loadshow = false
    this.loading = 'sending...'
    const id = this.afs.createId()
  await  this.uploadDocument(id, this.image); 
    setTimeout(() => {
        this.afs.collection('coach_registrations').doc(id).set({
          firstname: this.firstname, lastname: this.lastname, middlename: this.middlename, sex: this.sexies, dateofbirth: this.dateofbirth,
          nationality: this.nationality, stateoforigin: this.stateoforigin, stateofresidence: this.stateofresidence, 
          homeaddress: this.homeaddress, phoneno: this.phoneno, whatsappno: this.whatsappno, emailaddress: this.emailaddress, 
          spellername: this.spellername, spellercode: this.spellercode, photo: this.image, coachreg_id: id
      }).then(() => {      
        setTimeout(() => {
          this.loadshow = true
          this.message = 'Form submitted successful.'
        }, 3000)

      }).finally(() => {
        this.firstname = '', this.lastname = '', this.middlename = '', this.sexies = '', this.dateofbirth = '', this.nationality = '',
        this.stateoforigin = '', this.stateofresidence = '', this.homeaddress = '', this.phoneno = 0, this.whatsappno = '',
        this.emailaddress = '', this.spellername = '', this.spellercode = 0, this.image = '' 
      })
    }, 10000)


  }

async  changeListener(event: any){
  this.uploadFlag = true;
  console.log(event)
    
// console.log(Date.now(), 'here now');
    const maxAllowedSize = this.maxFileSize;
    if (event.target.files[0].size > maxAllowedSize) {
      // Here you can ask your users to load correct file
      //  event.target.value = 'File size is to large'
      this.errorshow = false
       this.uploaderror = 'File size is to large'
      //  console.log('size error', event.target.value)
    }
    else {
      this.errorshow = true
      const file = event.target.files[0];
      const filePath ='coachimage/' + Date.now() + '.jpg';
      const fileRef = this.afstorage.storage.ref(filePath);
      const task = fileRef.put(file);
    
      this.uploadPercent = task.snapshot.state;
  
      // console.log('here now 2', this.uploadPercent);
  
      
  
     await task.then((snap)=>{
        snap.ref.getDownloadURL().then((url)=> {
          this.uploadPercent =  task.snapshot.state;
          // console.log('here now 3', this.uploadPercent);
          // this.uploadDocument(this.id, url);
          // console.log("DOWNLOAD URL = ", url);
          this.image = url
          // console.log('here now 4', this.image);
  
        })
        
      })
    }

    
  }

uploadDocument(information: string, id: any){
    const fileRef = this.afstorage.storage.ref().child('coachimage/');
    const uploadTask = fileRef.putString(information);
    

    return new Promise((resolve, reject) => {
        uploadTask.on('state_changed', snapshot => {
    },  error => {
          reject(error);
    
    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        // console.log('teste resolve url'+ downloadURL);
    
        resolve ( {downloadURL});
      });
    
    });
    
    }); 
  }
}
