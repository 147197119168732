import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  data = [
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4026.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4026.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4075.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4075.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4030.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4030.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4108.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4108.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4035.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4035.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4040.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4040.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4046.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4046.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4048.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4048.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4255.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4255.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4280.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4280.JPG'},

    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4283.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4283.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4289.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4289.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4290.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4290.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4303.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4303.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4306.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4306.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4309.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4309.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4310.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4310.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4311.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4311.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4317.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4317.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4321.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4321.JPG'},

    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4324.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4324.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4328.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4328.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4329.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4329.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4330.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4330.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4332.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4332.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4333.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4333.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4335.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4335.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4343.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4343.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4344.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4344.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4358.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4358.JPG'},

    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4399.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4399.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4405.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4405.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4406.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4406.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4409.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4409.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4425.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4425.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4430.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4430.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4432.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4432.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4434.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4434.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4435.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4435.JPG'},
    {previewImageSource: 'assets/img/gallery/firstedition/BBB_4437.JPG', imageSource: 'assets/img/gallery/firstedition/BBB_4437.JPG'},
  ]

  gridsimages = [
// 2nd Year Edition

    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8433.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8433.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8437.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8437.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8441.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8441.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8450.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8450.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7966.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7966.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7971.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7971.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7973.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7973.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7975.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7975.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7976.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7976.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7977.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7977.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7980.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7980.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7982.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7982.jpg'},


    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8181.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8181.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8177.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8177.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8211.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8211.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7911.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7911.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7913.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7913.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7917.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7917.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7985.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7985.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7989.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7989.jpg'},

    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7965.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7965.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7990.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7990.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7995.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7995.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7996.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7996.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7997.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7997.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8001.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8001.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8002.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8002.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8004.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8004.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8008.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8008.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8010.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8010.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8015.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8015.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8043.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8043.jpg'},


    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8047.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8047.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8048.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8048.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8050.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8050.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8051.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8051.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8054.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8054.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8064.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8064.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8070.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8070.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8095.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8095.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8109.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8109.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8119.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8119.jpg'},


    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8418.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8418.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8411.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8411.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8409.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8409.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8407.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8407.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8404.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8404.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8402.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8402.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8395.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8395.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8393.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8393.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8389.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8389.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8368.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8368.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8360.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8360.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8359.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8359.jpg'},

    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7784.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7784.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7792.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7792.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7794.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7794.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7882.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7882.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7889.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7889.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7895.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7895.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7933.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7933.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7901.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7901.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8126.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8126.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8127.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8127.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7896.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7896.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7907.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7907.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC7804.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC7804.jpg'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4086.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4086.JPG'},


    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8349.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8349.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8348.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8348.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8345.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8345.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8333.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8333.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8326.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8326.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8304.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8304.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8273.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8273.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8274.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8274.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8270.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8270.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8264.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8264.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8254.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8254.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8253.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8253.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8223.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8223.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8279.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8279.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8299.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8299.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8214.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8214.jpg'},


    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8130.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8130.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8157.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8157.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8160.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8160.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8163.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8163.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8168.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8168.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8170.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8170.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8176.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8176.jpg'},
    {previewImage: 'assets/img/gallery/secondedition/grid/_DSC8183.jpg', imageSource: 'assets/img/gallery/secondedition/grid/_DSC8183.jpg'},




    // 1ST Year Edition
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4192.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4192.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4144.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4144.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4142.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4142.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4140.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4140.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4138.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4138.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4104.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4104.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4121.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4121.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4189.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4189.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4195.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4195.JPG'},

    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4261.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4261.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4260.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4260.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4207.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4207.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4204.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4204.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4263.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4263.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4200.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4200.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4199.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4199.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4264.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4264.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4197.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4197.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4498.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4498.JPG'},

    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4256.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4256.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4391.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4391.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4467.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4467.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4421.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4421.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4106.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4106.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4190.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4190.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4185.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4185.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4184.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4184.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4180.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4180.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4098.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4098.JPG'},

    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4179.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4179.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4177.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4177.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4174.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4174.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4172.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4172.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4102.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4102.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4103.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4103.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4169.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4169.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4167.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4167.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4166.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4166.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4161.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4161.JPG'},

    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4099.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4099.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4160.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4160.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4157.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4157.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4154.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4154.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4153.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4153.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4150.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4150.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4393.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4393.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4474.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4474.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4146.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4146.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4101.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4101.JPG'},

    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4136.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4136.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4133.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4133.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4132.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4132.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4130.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4130.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4128.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4128.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4125.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4125.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4124.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4124.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4105.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4105.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4065.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4065.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4061.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4061.JPG'},

    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4119.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4119.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4117.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4117.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4113.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4113.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4068.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4068.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4054.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4054.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4395.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4395.JPG'},
    {previewImage: 'assets/img/gallery/firstedition/grid/BBB_4471.JPG', imageSource: 'assets/img/gallery/firstedition/grid/BBB_4471.JPG'},
  ]

  public grids: any

  constructor() { }

  ngOnInit() {
    this.grids = this.gridsimages
  }
}
