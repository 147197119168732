<div class="about-title about-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="about-title-text">
                    <h1>About Us</h1>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-rounded-double-right"></i> About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-style-two about-style-three pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 p-0">
                <div class="about-img" style="text-align: center;">
                    <img src="assets/img/THEBEE1.png" alt="about image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-text">   
                    <div class="section-head">
                        <h2>MISSION</h2>
                        <p>To expose the Nigerian education sector to the entire world as a pool for the best of brains.</p>
                    </div>

                    <div class="section-head">
                        <h2>AIMS & OBJECTIVES</h2>
                        <ul>
                            <li class="lh">
                                <i class="icofont-check-circled"></i>
                                To help students to improve their English to international accepted standard.
                            </li>
                            <li class="lh">
                                <i class="icofont-check-circled"></i>
                                Helps to motivate students in their studies as they watch their peers do well.    
                            </li>
                            <li class="lh">
                                <i class="icofont-check-circled"></i>
                                Provide edutainment for the students in schools. This gives an alternative to healthy,
                                 but entertaining programming.                            
                            </li>
                            <li class="lh">
                                <i class="icofont-check-circled"></i>
                                Provides a platform for corporate world to come in and positively help the educational sector.   
                            </li>
                            <li class="lh">
                                <i class="icofont-check-circled"></i>
                                It is also an avenue to raise fund toward a particular project in the community.    
                            </li>
                        </ul>
                      
                    </div>

   
                </div>
            </div>
        </div>
    </div>
</section>

<div class="counter-section pt-100 pb-70 mb-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="offer-text">
                    <h2>Last Year <span>Project</span> on the BEE</h2>
                    <div class="theme-button">
                        <a routerLink="/" class="default-btn">Check Gallery</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="counter-area">
                    <div class="row">
                        <div class="col-lg-5 col-md-3 col-6 offset-lg-1">
                            <div class="counter-text">
                                <h2><span class="counter">1</span>K</h2>
                                <p>Speller</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6">
                            <div class="counter-text">
                                <h2><span class="counter">35</span></h2>
                                <p>Spelling Bee Coach</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6 offset-lg-1">
                            <div class="counter-text">
                                <h2><span class="counter">15</span></h2>
                                <p>Schools</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6">
                            <div class="counter-text">
                                <h2><span class="counter">12</span></h2>
                                <p>Awards</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="counter-shape">
            <img src="assets/img/counter/1.png" alt="shape">
            <img src="assets/img/counter/2.png" alt="shape">
            <img src="assets/img/counter/3.png" alt="shape">
            <img src="assets/img/counter/4.png" alt="shape">
            <img src="assets/img/counter/5.png" alt="shape">
            <img src="assets/img/counter/6.png" alt="shape">
            <img src="assets/img/counter/7.png" alt="shape">
            <img src="assets/img/counter/1.png" alt="shape">
            <img src="assets/img/counter/8.png" alt="shape">
            <img src="assets/img/counter/4.png" alt="shape">
            <img src="assets/img/counter/shape-1.png" alt="bubble shape">
            <img src="assets/img/counter/shape-1.png" alt="bubble shape">
        </div>
    </div>
</div>

<section class="about-style-two about-style-three pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 p-0">
                <div class="about-text about-text2">   
                    <div class="section-head">
                        <h2>About <br> <span> OBA AYANGBUREN & THE BEE</span></h2>
                        <p>Children without doubt are the delight of the world. Their innocent views and free perspective on issues
                             around them buttress the universal belief that the qualitative development of any society is dependent
                              upon that of the children.
                        </p>
                        <p>Studies have also shown that the socio-economic and moral development of our children is not limited to 
                            the four walls of the classroom; they need an atmosphere for interaction, exposure and learning that is 
                            not too formal, yet conducive and impactive. Hence, many private academic centres have taken the charge to
                             stimulate educational development within-class as well as off-class activities.
                        </p>
                        <p>One of those activities is a Spelling Bee contest which is an initiative that allows students of the same age 
                            to compete in a spelling challenge. It is indeed an exercise that exposes students to a challenge that enhances their 
                            language skills amongst other things. The suspense and emotions in this challenge is akin to that of reality/game TV show;
                             hence, it is safe to say that a well-packaged spelling bee contest is a double edged initiative that will provide entertainment 
                             as well as education.
                        </p>
                        <p>Against this background, Oba Ayangburen and the bee was conceptualized to be an annual spelling bee competition between pupils
                             of both private and public primary schools pupils across Africa continent.
                        </p>
                        <p>This contest will raise the standard of English and linguistics in our schools. By extension, it is safe to say that the contest 
                            will be double edged as it will provide Entertainment as well as richly Educative.
                        </p>
                    </div>

                </div>
            </div>

            <div class="col-lg-6 ">
                <div class="about-img">
                    <img width="700px" src="assets/img/THEBEE22.png" alt="Oba">
                </div>
            </div>
        </div>
    </div>
</section>
