<div class="contact-title contact-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="contact-title-text">
                    <h1>Contact Us</h1>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Contact Us
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-style-two about-style-three pt-100 pb-70">
    <div class="container-fluid">
        <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=Ikorodu kings Palace&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://2yu.co">2yu</a><br><style>.mapouter{position:relative;text-align:right;height:100%;width:100%;}</style><a href="https://embedgooglemap.2yu.co/">html embed google map</a><style>.gmap_canvas {overflow:hidden;background:none!important;height:100%;width:100%;}</style></div></div>
    </div>

    <div class="container-fluid info_mt">
        <div class="info-section">
            <div class="container">
                <div class="info-area">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="info-text">
                                <i class="icofont-whatsapp"></i>
                                <h6>WhatsApp</h6>
                                <p>+2347030333363</p>
                            </div>
                        </div>
        
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="info-text">
                                <i class="icofont-location-pin"></i>
                                <h6>Address</h6>
                                <p>Afin Oba Ayamgburen,palace road Ikorodu Lagos.c/o Scenerio Amusement Park,Igbodu Ikorodu Lagos</p>
                            </div>
                        </div>
        
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="info-text">
                                <i class="icofont-phone"></i>
                                <h6>Call</h6>
                                <p>+234 703 0333 363 <br>
                                    +234 912 1556 160</p>
                            </div>
                        </div>
        
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="info-text">
                                <i class="icofont-ui-message"></i>
                                <h6>Mail Us</h6>
                                <p>obaayangburenspellingbee@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="pt-100"></div>