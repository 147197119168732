import { Component } from '@angular/core';

@Component({
  selector: 'app-sme-supports',
  templateUrl: './sme-supports.component.html',
  styleUrls: ['./sme-supports.component.scss']
})
export class SmeSupportsComponent {

}
