<div class="team-title team-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="team-title-text">
                    <h2>Gallery</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Gallery
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="team-section team-style-four pb-70">
    <div class="container">

        <div class="row" style="margin-top: 30px;">
            <div class="col-lg-3 col-md-6" *ngFor="let grid of gridsimages">
                <div class="team-card">
                    <div class="team-img">
                        <img src="{{grid?.previewImage}}" alt="Gallery">
                        <div class="team-social">                           
                            <a href="{{grid?.imageSource}}"><i class="icofont-eye"></i></a>                       
                        </div>
                    </div>
                </div>
            </div> 

        </div>

        <gl-gallery [value]="data" [itemSize]="50">
            <ng-template template="preview" let-data>
                <img src="{{ data.previewImageSource }}" alt="preview"/>
            </ng-template>
            <ng-template template="item" let-data>
                <img src="{{ data.imageSource }}" alt="image" style="block-size: 50px; inline-size: 100px;"/>
            </ng-template>
        </gl-gallery>

    </div>
</div>