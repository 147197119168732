import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServiceComponent } from './components/pages/service/service.component';
import { ServiceTwoComponent } from './components/pages/service-two/service-two.component';
import { ServiceThreeComponent } from './components/pages/service-three/service-three.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { RecentProjectComponent } from './components/pages/recent-project/recent-project.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TeamTwoComponent } from './components/pages/team-two/team-two.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { SchoolRegistrationComponent } from './components/pages/school-registration/school-registration.component';
import { CoachRegistrationComponent } from './components/pages/coach-registration/coach-registration.component';
import { RulesComponent } from './components/pages/rules/rules.component';
import { VolunteerFormComponent } from './components/pages/volunteer-form/volunteer-form.component';
import { GuarantorFormComponent } from './components/pages/guarantor-form/guarantor-form.component';
import { SpellerRegistrationComponent } from './components/pages/speller-registration/speller-registration.component';
import { ParticipationComponent } from './components/pages/participation/participation.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { SponsorsComponent } from './components/pages/sponsors/sponsors.component';
import { PartnersComponent } from './components/pages/partners/partners.component';
import { SupportsComponent } from './components/pages/supports/supports.component';
import { VolunteersComponent } from './components/pages/volunteers/volunteers.component';
import { GuardianFormComponent } from './components/pages/guardian-form/guardian-form.component';
import { SponsorChildComponent } from './components/pages/sponsor-child/sponsor-child.component';
import { CorporateSponsorComponent } from './components/pages/corporate-sponsor/corporate-sponsor.component';
import { CoordinatorComponent } from './components/pages/coordinator/coordinator.component';
import { RefereeFormComponent } from './components/pages/referee-form/referee-form.component';
import { WinnersComponent } from './components/pages/winners/winners.component';
import { SuccessfullComponent } from './components/pages/successfull/successfull.component';
import { DownloadComponent } from './components/pages/download/download.component';

const routes: Routes = [
    {path: 'home-one', component: HomeOneComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: '', component: HomeThreeComponent},
    {path: 'about', component: AboutComponent},
    {path: 'service', component: ServiceComponent},
    {path: 'service-two', component: ServiceTwoComponent},
    {path: 'service-three', component: ServiceThreeComponent},
    {path: 'service-details', component: ServiceDetailsComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-right-sidebar', component: BlogRightSidebarComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'recent-project', component: RecentProjectComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'team', component: TeamComponent},
    {path: 'team-two', component: TeamTwoComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'school-registration', component: SchoolRegistrationComponent},
    {path: 'coach-registration', component: CoachRegistrationComponent},
    {path: 'rules', component: RulesComponent},
    {path: 'volunteer-form', component: VolunteerFormComponent},
    {path: 'guarantor-form/:volunteer_reg_id', component: GuarantorFormComponent},
    {path: 'speller-registration', component: SpellerRegistrationComponent},
    {path: 'participation', component: ParticipationComponent},
    {path: 'gallery', component: GalleryComponent},
    {path: 'sponsors', component: SponsorsComponent},
    {path: 'partners', component: PartnersComponent},
    {path: 'supports', component: SupportsComponent},
    {path: 'volunteers', component: VolunteersComponent},
    {path: 'guardian-form/:spellerreg_id', component: GuardianFormComponent},
    {path: 'sponsor-child', component: SponsorChildComponent},
    {path: 'corporate-sponsor', component: CorporateSponsorComponent},
    {path: 'coordinator', component: CoordinatorComponent},
    {path: 'referee-form/:coordinator_id', component: RefereeFormComponent},
    {path: 'winners', component: WinnersComponent},
    {path: 'successfull/:spellerreg_id', component: SuccessfullComponent},
    {path: 'download/:spellerreg_id', component: DownloadComponent},


    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }