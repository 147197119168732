<div class="about-title about-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="about-title-text">
                    <h1>Partners</h1>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-rounded-double-right"></i> Partners</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-style-two about-style-three pt-100 pb-70">
    <div class="section-head text-center">
        <h1 class="connection">Our Partners</h1>
    </div>
    <div class="container-fluid">
        <div class="row align-items-center">

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/sponsors/epeetechsolution.png" alt="Oba Ayangburen & The Bee, Partnership Logo Epee Tech Solution " width="50%">
                </div>
                <div class="section-head">
                    <h2>Epee Tech <br> Solution</h2>
                </div>
            </div>

            <div class="col-lg-3 p-0" style="text-align: center;">
                <div class="about-img" >
                    <img src="assets/img/sponsors/KINGSCOLLEGE.png" alt="Oba Ayangburen & The Bee, Partnership Logo KINGS COLLEGE" width="50%">
                </div>
                <div class="section-head">
                    <h2>Kings Field <br> College</h2>
                </div>
            </div>

        </div>
    </div>
</section>

