<div class="blog-title blog-title-bg bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="blog-title-text">
                    <h2>Blogs Right Sidebar</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Blogs Right Sidebar
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-section blog-style-two blog-details-area blog-left-sidebar pt-100 blog-right-sidebar-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-md-6">
                        <div class="blog-area">
                            <div class="blog-img">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/1.png" alt="blog image">
                                </a>
                            </div>
                            <div class="blog-text">
                                <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
                                <ul>
                                    <li>
                                        <i class="icofont-calendar"></i>
                                        20 Feb 2020
                                    </li>
                                    <li>
                                        <i class="icofont-ui-user"></i>
                                        <a href="#">John Doe</a>
                                    </li>
                                </ul>
    
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et.</p>
    
                                <a routerLink="/blog-details" class="blog-btn">
                                    Read more
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="blog-area">
                            <div class="blog-img">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/2.png" alt="blog image">
                                </a>
                            </div>
                            <div class="blog-text">
                                <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
                                <ul>
                                    <li>
                                        <i class="icofont-calendar"></i>
                                        20 Feb 2020
                                    </li>
                                    <li>
                                        <i class="icofont-ui-user"></i>
                                        <a href="#">John Doe</a>
                                    </li>
                                </ul>
    
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et.</p>
    
                                <a routerLink="/blog-details" class="blog-btn">
                                    Read more
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="blog-area">
                            <div class="blog-img">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/3.png" alt="blog image">
                                </a>
                            </div>
                            <div class="blog-text">
                                <h4><a routerLink="/blog-details">Self cleaning is the best drill</a></h4>
                                <ul>
                                    <li>
                                        <i class="icofont-calendar"></i>
                                        20 Feb 2020
                                    </li>
                                    <li>
                                        <i class="icofont-ui-user"></i>
                                        <a href="#">John Doe</a>
                                    </li>
                                </ul>
    
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et.</p>
    
                                <a routerLink="/blog-details" class="blog-btn">
                                    Read more
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="blog-area">
                            <div class="blog-img">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/4.png" alt="blog image">
                                </a>
                            </div>
                            <div class="blog-text">
                                <h4><a href="#">How to clean your office</a></h4>
                                <ul>
                                    <li>
                                        <i class="icofont-calendar"></i>
                                        20 Feb 2020
                                    </li>
                                    <li>
                                        <i class="icofont-ui-user"></i>
                                        <a href="#">John Doe</a>
                                    </li>
                                </ul>
    
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et.</p>
    
                                <a routerLink="/blog-details" class="blog-btn">
                                    Read more
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="main-blog-post">
                    <nav>
                        <ul class="pagination  justify-content-center">
                            <li class="page-item disabled">
                                <a class="page-link" href="#" tabindex="-1" aria-disabled="true">
                                    <i class="icofont-rounded-left"></i>
                                </a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">1</a>
                            </li>
                            <li class="page-item" aria-current="page">
                                <a class="page-link active" href="#">2</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">3</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div class="col-lg-4 main-service-area blog-side-bar">
                <div class="service-sidebar">
                    <form class="search-form">
                        <div class="form-group">              
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit" class="btn btn-primary">
                                <i class="icofont-search-1"></i>
                            </button>
                        </div>
                    </form>
                    
                    <div class="service-list">
                        <p>Categories</p>                            
                        <ul>
                            <li>
                                <a href="#">
                                    Toilet Cleaning 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Stay Hygienic 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    News
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Cleaning Floor
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Cleaning Kitchen
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Wash Home
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="tags">
                        <p>Most Popular Tags</p>
                        <a href="#">Enjoy</a>
                        <a href="#">Clean</a>
                        <a href="#">Hygienic</a>
                        <a href="#">Time</a>
                        <a href="#">Brush</a>
                        <a href="#">Wash</a>
                        <a href="#">Call</a>
                    </div>

                    <div class="discount-text">
                        <h1>Get <span>15% Discount</span> on your first Service</h1>

                        <div class="theme-button">
                            <a href="#" class="default-btn">Book Now</a>
                        </div>

                        <div class="discount-shapes">
                            <img src="assets/img/counter/1.png" alt="shape">
                            <img src="assets/img/counter/2.png" alt="shape">
                            <img src="assets/img/counter/3.png" alt="shape">
                            <img src="assets/img/counter/4.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                            <img src="assets/img/counter/6.png" alt="shape">
                            <img src="assets/img/counter/7.png" alt="shape">
                            <img src="assets/img/counter/8.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>