<div class="pricing-title bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <h2>Best Prices</h2>

            <ul>
                <li>
                    <a routerLink="/">Home</a>
                </li>
                <li>
                    <i class="icofont-rounded-double-right"></i>
                    Prices
                </li>
            </ul>
        </div>
    </div>
</div>

<section class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Our <span>Pricing Plan</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="price-card-area">
                    <div class="price-head-text">
                        <h4>Basic Plan</h4>
                        <h2>$<span>60</span>/month</h2>
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>
                                Full Cleaning
                            </li>
                            <li>
                                <i class="icofont-check-circled"></i>
                                All Our Equipment
                            </li>
                            <li>
                                <i class="icofont-check-circled"></i>
                                5 Hours
                            </li>
                            <li>
                                <i class="icofont-check-circled"></i>
                                1 time in a week
                            </li>
                            <li>
                                <i class="icofont-check-circled"></i>
                                Fully Satisfaction
                            </li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Booking Now</a>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>Standard Plan</h4>
                        <h2>$<span>99</span>/month</h2>
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>
                                Full Cleaning
                            </li>
                            <li>
                                <i class="icofont-check-circled"></i>
                                All Our Equipment
                            </li>
                            <li>
                                <i class="icofont-check-circled"></i>
                                7 Hours
                            </li>
                            <li>
                                <i class="icofont-check-circled"></i>
                                1 time in a week
                            </li>
                            <li>
                                <i class="icofont-check-circled"></i>
                                Fully Satisfaction
                            </li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Booking Now</a>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="price-card-area">
                    <div class="price-head-text">
                        <h4>Premium Plan</h4>
                        <h2>$<span>159</span>/month</h2>
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>
                                Full Cleaning
                            </li>
                            <li>
                                <i class="icofont-check-circled"></i>
                                All Our Equipment
                            </li>
                            <li>
                                <i class="icofont-check-circled"></i>
                                5 Hours
                            </li>
                            <li>
                                <i class="icofont-check-circled"></i>
                                1 time in a week
                            </li>
                            <li>
                                <i class="icofont-check-circled"></i>
                                Fully Satisfaction
                            </li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Booking Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>