import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-guarantor-form',
  templateUrl: './guarantor-form.component.html',
  styleUrls: ['./guarantor-form.component.scss']
})
export class GuarantorFormComponent implements OnInit {

  arrayGender = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    ];

  
  public sexies: any

  public id: any
  public firstname: string
  public lastname: string
  public sex: string = ''
  public work: string
  public homeaddress: string
  public phoneno: number
  public emailaddress: string
  public relationship: string
  public employeraddress: string
  public nin: string

  public loading: string
  public loadshow: boolean
  public errorshow: boolean
  public message: any

  constructor(public afs: AngularFirestore, public afa: AngularFireAuth, public router: Router,
    public afstorage: AngularFireStorage, public ar: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('volunteer_reg_id')
  }

  changeSex(value) {
    this.sexies = value;
  }

async  submit() {
    this.loadshow = false
    this.loading = 'sending...'
  await  setTimeout(() => {
        this.afs.collection('volunteer_registrations').doc(this.id).update({
          gun_firstname: this.firstname, gun_lastname: this.lastname,  gun_sex: this.sexies,
          gun_homeaddress: this.homeaddress, gun_phoneno: this.phoneno, gun_work: this.work, relationship: this.relationship,
          gun_emailaddress: this.emailaddress,  gun_nin: this.nin, gun_employeraddress: this.employeraddress
      }).then(() => {      
        setTimeout(() => {
          this.loadshow = true
          this.message = 'Form submitted successful.'
        }, 3000)

      }).finally(() => {
        this.firstname = '', this.lastname = '',  this.sex = '', this.work = '', this.relationship = '',
        this.homeaddress = '', this.phoneno = 0,  this.emailaddress = '', this.nin = '', this.employeraddress = ''
      })
    }, 10000)


  }

}
