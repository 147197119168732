<div class="service-title service-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Corporate Sponsor</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-rounded-double-right"></i> Corporate Sponsor </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="main-service-area single-service-page pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="contact-section">
                        <div class="contact-area">
                            <div class="row align-items-center">            
                                <div class="col-lg-12">
                                    <div class="contact-text">
                                        <div class="section-head">
                                            <h2>OAATB23 Corporate Sponsor Registration</h2>
                                            <!-- <p class="subtext">Kindly provide details of your school below, your letter will be dispatched to your school 
                                                within 7 working days.</p> -->

                                            <!-- <p>{{time}}</p> -->
                                        </div>
                                        <div class="contact-form">
                                            <form id="contactForm">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label>Full Name <span class="request">*</span></label>
                                                            <input required type="text" name="full_name" id="full_name" class="form-control"
                                                            [(ngModel)]="full_name" [ngModelOptions]="{standalone:true}">
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <label> Phone Number <span class="request">*</span></label>
                                                            <input required type="number" name="phoneno" id="phoneno" class="form-control"
                                                            [(ngModel)]="phoneno" [ngModelOptions]="{standalone:true}">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12 col-sm-6">
                                                        <div class="form-group mb-3">
                                                            <label> Email Address <span class="request">*</span></label>
                                                            <input required type="email" name="emailaddress" id="emailaddress" class="form-control"
                                                            [(ngModel)]="emailaddress" [ngModelOptions]="{standalone:true}">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12 col-sm-6">
                                                        <div class="form-group mb-3">
                                                            <label> More Details <span style="font-size: 12px; font-weight: 500;"> (optional) </span></label>
                                                            <textarea rows="6" type="text" name="desc" id="desc" class="form-control"
                                                            [(ngModel)]="desc" [ngModelOptions]="{standalone:true}" >

                                                            </textarea>
                                                        </div>
                                                    </div>
                                        
                                                
                                                    <div class="col-lg-12 col-md-12">
                                                        <button type="submit" class="default-btn page-btn" 
                                                        [disabled]="!full_name || !phoneno || !emailaddress "
                                                        (click)="submit()"
                                                        >Submit</button>
                                                            <p *ngIf="!loadshow">{{loading}}</p>
                                                    </div>

                                                    <div class="col-lg-12 col-md-12">
                                                        <p class="success">{{message}}</p>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>               
                                </div>
                            </div>
                        </div>
                </div>
            </div>
  
            
            <div class="col-lg-4 main-service-area blog-side-bar sidebar">
                <div class="service-sidebar">
                    <!-- <form class="search-form">
                        <div class="form-group">              
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit" class="btn btn-primary">
                                <i class="icofont-search-1"></i>
                            </button>
                        </div>
                    </form> -->
                    
                    <div class="service-list">
                        <p>Other Links</p>
                        <ul>
                            <li>
                                <a routerLink="/speller-registration">
                                    Speller Registration
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/school-registration">
                                    School Registration
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/coach-registration">
                                    Coach Registration
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/rules">
                                    Find Out About The Rules
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/participation">
                                    More about participation
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="discount-text">
                        <h1>Get <span>15% Discount</span> on your first Service</h1>

                        <div class="theme-button">
                            <a routerLink="/" class="default-btn">Book Now</a>
                        </div>

                        <div class="discount-shapes">
                            <img src="assets/img/counter/1.png" alt="shape">
                            <img src="assets/img/counter/2.png" alt="shape">
                            <img src="assets/img/counter/3.png" alt="shape">
                            <img src="assets/img/counter/4.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                            <img src="assets/img/counter/6.png" alt="shape">
                            <img src="assets/img/counter/7.png" alt="shape">
                            <img src="assets/img/counter/8.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>